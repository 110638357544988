.o-popular-searches {
	position: relative;
	width: 100%;
	border: 1px solid $gray-light;
	border-radius: 3px;

	.o-search-item {
		border-bottom: 1px solid $gray-light;
		padding: 20px 20px 15px;

		&:last-child {
			border-bottom: none;
		}

		span {
			margin-left: 15px;
			letter-spacing: .25px;
		}
	}
}