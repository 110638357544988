/* General */
.container{
  //padding: 0px; //Don't ever set this BS-class as something unexpected.
}
/* Filter header */
.fm-ul-nav li {

  a {
    display: inline-block;
    padding-left: 1.4em;
    font-size:15px;
    line-height: 21px;
    letter-spacing: 0.05em;
  }

  &.active {
    a {
      color: $redesign-dark;
    }
  }
}
.btn-wide.btn-dropdown:after {
  &:after {
    float: right;
    margin-left: 15px;
    content: url("../../gfx/icons/dropdown-blue.svg");
    width: 24px;
  }

  &.btn-dropdown {
    text-align: right;
    padding-right: 0px;
  }
}
.fm-dropdown-open .btn-dropdown::after{
  content: url("../../gfx/icons/dropdown-up-blue.svg");
}
.fm-dropdown-content{
  border:0px;
}

.fm-ul-nav li:hover::before,
.filter-ordinary-list li:hover::before,
.fm-ul-nav li.current::before,
.filter-ordinary-list li.current::before,
.fm-ul-nav li.active::before{
  box-shadow: #062834 0 0 0 1px;
}

.sidebar-box{
  margin: 10px 0px 15px;
  
  li {
    margin: 0.25em 0;
    .sidebar-box--number{
      color: $redesign-gray;
      margin-right:3px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      position: absolute;
      right: 0;
      top: 0;

    }

    &.active {
      .sidebar-box--number {
        color: $redesign-dark;
      }
    }
  }
}
.filter-widget-body .sidebar-box-input{
  margin: 1em 0px;
}

.filter-toggle {
  font-size: 1em;
  position: relative;
  padding-right: 3em;

  .icon-filter {
    position: absolute;
    top: 15px;
    margin-left: 10px;
  }
}

.filter-widget {
  position: relative;
}

.filter-widget-heading{
  transition: all .5s ease;
  padding: 15px 0px;
  font-weight: 500;
  border-top: 1px solid $gray-lighter;
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 0.1em;
  
  &::after {
    float:right;
    width: 13px;
  }
}

.widget-open{
  padding-bottom: 0px!important;

  &:after{
    content: url("../../gfx/icons/dropdown-up-blue.svg");
  }
}

.widget-close::after{
  content: url("../../gfx/icons/dropdown-blue.svg");
}

.filter-widget-body{
  max-height:0px;
  transition: all .5s ease;
  overflow: hidden;
  opacity: 0;

  & .filter-size{
    max-height: none;
  }

  & .filter-color,
  & .filter-size {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;

    li {
      margin: 0 11px 11px 0;//0 12px 12px 0;
      flex: 0 1 24px;
      &:nth-child(6n){
        margin-right: 0;
      }
      a {
        height: 20px;
      }
    }
  }


  & .filter-size {
    li {
      a {
        height: 24px;
      }
    }
  }

  &::-webkit-scrollbar-track
  {
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar
  {
    width: 4px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $brand-marine;
  }

  &::-webkit-scrollbar
  {
    width: 6px;
    background-color: #F5F5F5;
  }
  
  @media all and (max-width: 830px){
    & .filter-color,
    & .filter-size {
      li {
        margin: 0 12px 12px 0;
        flex: 0 1 34px;
        a {
          height: 34px;
          width: 34px;
          font-size: 14px;
          line-height: 32px;
        }
      }
    }
    & .filter-color {
      li a {
        height: 31px;
        width: 31px;
      }
    }
  }
}

.flex-items-row {
  margin: 0;
}
.flex-items {
  display:flex;
  flex-flow: row wrap;
  margin: -1%;

  &.sale-btns {
    
  }

  .flex-item {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 1%;
    text-align: center;

    &.sale-btn {
      text-transform: uppercase;
      background-color: $fm-red-sale;
      color: $body-color;
      border: $fm-red-sale 1px solid;
      padding: 20px 10px;
      transition: background-color .3s ease, color .3s ease;
      cursor: pointer;

      svg {
        fill: $body-color;
        height: 17px;
        vertical-align: text-bottom;
        margin-right: 10px;
        transition: fill .3s ease;
      }

      &.active {
        background-color: $body-color;
        color: $fm-red-sale;
        svg {
          fill: $fm-red-sale;
        }
      }

      &:hover {
        background-color: $fm-red-sale - 10;
        color: $body-color; 
        transition: background-color .3s ease, color .3s ease;

        svg {
          fill: $body-color;
        }
      }
    }
  }
}
.m-btn-sale--red.active{
  background-color: #F14958!important;
  border-color: #F14958!important;
}
.widget-body-show{
  max-height: 270px;
  opacity: 1;
  overflow-y: auto;

  + .cover-bar {
    position: absolute;
    background: $body-color;
    height: calc(100% - 30px);  
    top: 30px;
    right: 0;
    width: 6px;
    transition: opacity .3s ease;
    opacity: 1;
  }

  &:hover + .cover-bar {
    opacity: 0;
    transition: opacity .3s ease;
  }
}

/* Sizes */

.filter-size{
  overflow: hidden;

  & li {
    float: left;
    width: 34px;

    &.active {
      a {
        background: $redesign-dark;
        color: $body-color;

        &:hover {
          background: $redesign-darker;
          color: $body-color;
        }
      }
    }

    &.inactive {
      background: $body-color;
      color: $gray-lighter;
      cursor: not-allowed;

      &:hover {
        background: $body-color;
        color: $gray-lighter;
        cursor: not-allowed;
      }
    }

    & a{
      width: 100%;
      height: 100%;
      text-align: center;
      display: inline-block;
      border: 1px solid $gray-lighter;
      border-radius: 100%;
      font-size: 11px;
      color: $redesign-dark;
      line-height: 22px;
      letter-spacing: 0.1em;
      font-weight: 400;
      padding-left: 1px;

      &.active{
        background: $redesign-dark;
        color: $body-color;
      }
      &.inactive{
        background: $body-color;
        color: $gray-lighter;
        cursor: not-allowed;
      }
    }

    & a:hover{
      text-decoration: none;
      color:inherit;
      
      &.active{
        background: $redesign-darker;
        color: $body-color;
      }
      &.inactive{
        background: $body-color;
        color: $gray-lighter;
        cursor: not-allowed;
      }
    }
  }
}


/* Brand filter */
.filter-search{
  border-radius: 50px!important;
}
.brand_list{
  overflow: hidden;
}


/* Sale */
.filter-widget__sale div{
  & h4{
    margin:0px;
    padding-left: 2px;
  }
  & #slider{margin: 10px}
  
  .noUi-origin {
    border-radius: 4px;    
  }
  .noUi-background,
  .noUi-origin:last-of-type {
    background-color: $gray-lighter;
  }
}
.filter-price-small::after,
.filter-price-small::before {
  content: none
}

#slider{margin-bottom:10px;}
.sale-slider{
  background-color: inherit;
  padding: .85em 5px;

  & li{
    background: $fm-red;
    border-radius: 50px;

    & a{
      display: block;
      text-align:right;
      overflow: hidden;
      font-size:12px;
      padding:10px 10px 4px;

      &:after{
        content: url('../../gfx/icons/off-white.svg');
        float: left;
        width: 20px;
        margin-left: 5px;
      }
    }
  }
}

/* Shops */

/* Filter reset */

.filter-btn{
  border: 0px;
  padding: 0px;
  text-align: left;
  margin-top: 15px;
  font-size: 12px;

  &:hover{
    border:0px;
  }
}

.filter-search.fm-input{
  padding-top:0.5em;
  padding-bottom:0.5em;
}

.fm-ul-nav li:before, .filter-ordinary-list li:before {
  box-shadow: rgba(119, 119, 119, 0.4) 0 0 0 1px;
  height: 13px;
  width: 13px;
}
.fm-ul-nav li .active:before,
.filter-ordinary-list li .active:before,
.fm-ul-nav li.active:before,
.filter-ordinary-list li.active:before{
  background-color: #062834;
}
.sidebar h3.toggle.filter-show {
  border-bottom: 0px solid #d9d9d9;
}

.marg-body{
  & .filter-cat{
    margin-top: 0px!important;
  }
  &.widget-body-show {
    margin-top: 10px;
  }
}
.min-marg-body.widget-body-show{
  margin-top: 5px;
}
a{
  &:focus{
    box-shadow: none;
    text-decoration: none;
  }
}

//.filter-widget {
  .breadcrumb-title {
    padding: 5px 0 15px;
    text-transform: uppercase;
    line-height:25px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    word-spacing: 2px;
    overflow-x: visible;
    width: 800px;

    a {
      font-size: 13px;
    }

    &--auto-width {
      width: auto;
    }

    &:after{
      content: none;
    }
  }
//}

.filter-btn-reset.btn {
  padding-left: 0;
}

/* Active filters */

.filter-search-tags{
  & li:first-of-type{
    margin-top: .7em;
  }

  & .btn-white,
  & .reset-filter:hover{
    border-color:#062834;
    //border-top-width: 1.5px;
    color: #062834;
    padding:0.8em 1.2em;
    line-height: 15px;

    &:before {
      margin-right: .5em;
      content: url(../../gfx/icons/close-blue.svg);
      float: left;
      height: 15px;
      width: 18px;
    }
  }
  & .reset-filter{
    border-color:#d9d9d9;
  }

  .btn-tag {
    &[data-type=color] {
      position: relative;
      padding-right: 2.2em;
    }
  }
}

.sidebar-main {
  .filter-body-text {
    width: 100%;
    padding: 0;

    p {
      font-size: 11px;
      color: $redesign-gray;
      text-align: justify;
    }
  }
}

@media all and (max-width: 1040px){
  .filter-search-tags{
    max-width: 100%;
  }
  .items-heading {
    .pagination {
        width: 100%;
    }
  }
}
@media all and (max-width: 830px){
  .filter-toggle {
    margin-top: 15px;
  }
  #filter-container {
    .breadcrumb-title {
      width: 100%;
      padding-top: 15px;
      font-size: 13px;
    }
  }
  .filter-search-tags{
    padding: 15px 0;
    margin-bottom: -0.8em;
    width: 100%;
  }
  .filter-widget-heading {
    transition: 0s;
    &::after {
      display: none;
    }
  }
  .filter-btn-reset.btn {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 0;
  }
  .sidebar-main {
    .items-header {
      text-align: center;
    }
    .filter-body-text {
      padding: 15px 15px 0px;
    }
  }
  .widget-open{
    padding-bottom: 1em !important;
  }
  .items-heading {
    .pagination {
      //border-top: 1px solid $gray-light;
      padding-top: 10px;
      .pagination-tally {
        margin-right: 0;
        h5 {
          color: $redesign-dark;
        }
      }
    }
  }
  .flex-items {
    &.sale-btns {
      margin-top: 15px;
    }
  }
}

@media all and (max-width: 500px){
  .flex-items {
    .flex-item {
      flex: 1 1 100%;
    }
  }
}