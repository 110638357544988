.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon-keyboard_arrow_down {
	float: right;
	margin-left: 4px;
  width: 14px;
  margin-top: 4px;

    path {
          fill: $base-dark;
        }

    &--small {
      @extend .icon-keyboard_arrow_down;
      margin-left: 5px;
      margin-right: 0;
      margin-top: 3px;

      path {
        fill: #fff;
      }
    }

}

.icon-keyboard_arrow_down--topbar {
  float: right;
  margin-top: 16px;
  margin-left: 3px;
  width: 12px;

  path {
          fill: $base-dark;
        }
}

.icon-keyboard_arrow_right {
  height: 10px;
  margin-top: 3px;
  float: right;
}

.icon-phone,
.icon-shopping,
.icon-pin {
  margin-top: 5px;
  margin-right: 15px;
}

.icon-heart {
  font-size: 20px;

  path {
    fill: $base-dark;
  }
}

.icon-phone {
  width: 18px;
}

.icon-shopping {
  width: 29px;
}

.icon-pin {
  width: 25px;
}

.icon-search {
  width: 22px;
  float: left;
}

.social-media-icons-container {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  margin-bottom: 0 !important;
  transition: ease-out .5s;

  &:hover {

    .icon-social-media path {
      fill: rgba(#fff, 1);
    }

    .icon-circle {
      border: 2px solid rgba(#fff, 1);
    }
  }
}

.social-media-icons-container:last-child   {
  margin-right: 0;
}

.icon-social-media {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  transition: ease .5s;

  path {
    fill: #fff;
  }
}

.icon-instagram {
  width: 15px;
}

.icon-facebook {
  width: 8px;
}

.icon-linkedin {
  width: 15px;
}

.icon-circle {
  position: absolute;
  z-index: 9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(#fff, .5);
  z-index: 10;
  transition: ease-out .5s;


}

.icon-plus {
  font-size: 16px;
  margin-right: 2px;
  float: left;
  margin-top: 1px;

  &--turkos {
    fill: $brand-turkos;
  }

  &--marine {
    fill: $brand-marine;
    margin-top: 2px;
  }
}

.facebook-letter {
  width: 23px;

  path {
    fill: $color-facebook;
  }
}

.icon-long-arrow-right {
  width: 20px;
  position: relative;
  margin-left: 5px;
  margin-top: -1px;
}


.icon-close-menu {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 21px;

  &__modal {
    top: 25px;
    right: 30px;
    fill: $base-dark;

    path {
      fill: $base-dark;
    }
  }
}

.icon-close-banner {
  // position: relative;
  // float: right;
  // top: 21px;
  // right: 15px;
  width: 20px;
  transform: rotate(180deg);
  opacity: .5;

  path {
    fill: #fff;
    top: 30px;
  }
}



