a:focus {
    outline: 0;
    box-shadow: 0 0 5px $fm-orange;
}

// Cookie consent window styling
.cc-window {
    min-height: 60px;
    transition: transform 375ms ease-out, opacity .15s ease-out !important;
    transform: translateY(0%);
    opacity: 1;

    @media screen and (max-width: $screen-sm) {
        span {
            font-size: 14px;
            line-height: 20px;
        }
    }
}
.banner-loose .cc-window {
    transition: transform 375ms ease-out, opacity .15s ease-out;
    transform: translateY(100%);
    opacity: 0;
}

.navbar {
    position: relative;
    margin-bottom: 0 !important;
    min-height: auto;

    .navbar-nav {
        margin-top: -10px;
    }
}

.navbar-default {
    border: none;
    background-color: #fff !important;
}

.navbar-fixed-top {
    border-width: 0 !important;
}

.navbar-form {
    float: left;
}

.navbar-nav.nav-justified > li{
    float:none;
}

.navbar-default .navbar-nav > .active > a, 
.navbar-default .navbar-nav > .active > a:hover, 
.navbar-default .navbar-nav > .active > a:focus {
    background-color: #fff !important;
}

.dropdown-menu {
    border-radius: 3px;
    border: none;
}

.backdrop {
    position: fixed;
    // overflow: auto;
    // overflow-y: scroll;
    overflow: hidden;
    top: 0;
    right: -15px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    // opacity: 10%;
    display: none;
    background-color: rgba($brand-marine, .2);
    transition: all .3s ease-in-out;

    &.open {
        top: 129px;
        display: block;
    }

    &.fixed-theme {
        top: 108px !important;
    }

    &.in {
        top: 149px;
        display: block;
    }
}

.mega-dropdown {
  position: static !important;
}

.mega-dropdown-menu {
    position: absolute;
    top: 35px;
    padding: 10px 0px 0px;
    width: 100%;
    box-shadow: none;
    border: none;
    z-index: 2010;
}

.mega-dropdown > a {
    text-transform: uppercase;
    color: $brand-marine !important;
    font-weight: 500;
    letter-spacing: .75px;
}

.mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0;
}

.mega-dropdown-menu > li > ul > li {
  list-style: none;
}

.mega-dropdown-menu > li > ul > li > a {
  display: block;
  color: $brand-marine;
  padding: 7px 5px 7px 0;
  font-size: 15px;
  letter-spacing: 1px;

    &:hover {
        color: $redesign-dark;
    }
}

.mega-dropdown-inner-btn {
    margin-top: 7px;
}

.mega-dropdown-menu > li ul > li > a:hover,
.mega-dropdown-menu > li ul > li > a:focus {
  text-decoration: none;
  background-color: none;
} 

.mega-dropdown-menu .dropdown-header {
  font-size: 14px;
  letter-spacing: 1.5px;
  color: $gray-light;
  padding: 0px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 400;
}

.navbar-default .navbar-nav > .open > a {
    background-color: #fff !important;

}

.nav-link-rea {
    color: $brand-danger !important;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 36px;
}

.dropdown-menu .divider--full {
    @extend .divider;

    margin-top: 20px;
    margin-bottom: 20px;
}

.o-list__alphabet-menu {

    li > a {
        font-size: 1.8rem !important;
        padding: 7px 11px 7px 0 !important;

    }
}

.divider-vertical {
    position: absolute;
    border-left: 1px solid $gray-lighter;
    height: 100%;
    left: -20%;
}


// Toggle btn

.navbar-toggle {
    position: relative;
    margin-top: -5px;
    margin-right: 15px;
    border: none;
    background: transparent !important;

    &:hover {
        background: transparent !important;
    }
    
    .icon-bar {
        background-color: $brand-marine !important;
    } 

}

.navbar-toggle__text {
    position: relative;
    right: 1px;
    font-size: 10px;
    color: $brand-marine;
}

// my town btn in o-topbar

.o-my-town.open {
    position: relative;
    z-index: 1040;
}

.open > ul.dropdown-menu.o-my-town__drop {
    position: absolute;
    left: -100%;
    background-color: #fff;
    box-shadow: none;
    border: 1px solid $gray-light;

    li {
        padding: 10px 5px;
    }

    li > a:hover {
        background-color: #fff !important;
    }
}


// personal page

.o-profile-section {
    position: absolute;
    right: 0;
    bottom: 15px;
    z-index: 10;
    transition: .4s;
}

.o-profile__content {
    position: relative;

    .o-profile__image {
        float: left;
        position: relative;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        background-color: $body-color;
    }
    img {
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        object-fit: cover;
        color: $body-color;

        &.not-logged-in {
            border: 1px solid $redesign-gray;
            padding: 7px;
        }
    }

    .not-profile-image {
        float: left;
        position: relative;
        border-radius: 50%;
        width: 40px;
        height:40px;
        text-align:center;
        line-height:40px;
        font-size:16px;
        color:#fff;
        margin-top: 2px;
        background:#25cbbd;
    }

    p {
        float: right;
        margin-top: 2px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: .75px;;
        margin-left: 10px;
        text-transform: uppercase;
    }
}

.nav-profile {
    top: 0;
    left: -35px;
    padding-top: 60px;
    box-shadow: none;
    border-bottom: 1px solid $gray-light;
    z-index: -1;
}

.o-wishing-section {
    position: absolute;
    right: 105%;
    bottom: initial;
    z-index: 10;
    top: 12px;
    margin-top: 2px;
    margin-right: 20px;
    text-align: right;
    width: 100%;

    span {
        position: relative;
        bottom: 5px;
        display: inline-block;
        font-size: 18px;
    }
}

// searchfield

.form-control {
    color: $brand-marine !important;
}

.search-form:focus {
    outline: 0 !important;
    border-left: none;
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    border-right: 1px solid $gray-light;
    box-shadow: none !important;
}

.m-search-field__main {
    position: absolute;
    bottom: 15px;
    max-width: 23%;
    margin-bottom: 0;
    z-index: 1;
}

.m-search-field__brand {
    // position: absolute;
    // bottom: 15px;
    // max-width: 23%;
    // margin-bottom: 0;
    // z-index: 10;
}
.fm-input-dropdown-open .m-search-field__brand .filter-brand-input{
    border-bottom: #ccc 1px solid;
}

.m-form-control--search {
    border-radius: 0 20px 20px 0 !important;
    padding-right: 10px !important;
    padding: 20px 8px;

}

.m-input-group-addon--search {
    border-radius: 20px 0 0 20px !important;
    background-color: #fff;

    svg {
        position: relative;
        z-index: 201;
        margin-left: 5px;
        margin-right: -15px;
        height: 20px;
        path {
            fill: $base-dark;
        }
    }
}

.navbar-fixed-top.fixed-theme {
    background-color: #fff;
    border-color: none;
}

.main-logo.fixed-theme {
    width: 140px;
    margin-top: -10px;
}

.navbar-container.fixed-theme {
    padding: 0;
}

.main-logo.fixed-theme,
.navbar-container.fixed-theme,
.navbar.navbar-fixed-top.fixed-theme,
.main-logo,
.navbar-container,
.o-wishing-section {
    transition: .3s;
}

// Searchresults
nav#header-searchresults {
    height: 0;
    top: -40px;
}

#searchresults {
    width: 100%;
    display: none;

    &.open{
        display: inline-block;
        overflow-y: scroll;
    }
    &.keyboard {
        background-color: pink;
    }
    .divider-vertical {
        left: 0;
    }

    #searchresults-shops {
        //border-top: 1px solid $gray-lighter;
    }

    .searchresults-ul > li > a {
        padding-right: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    [data-type="brand"]:after{
        content: 'Märke'; // TODO: Another solution that is better to change language with
        color: $gray-light;
        position: absolute;
        right: 15px;
    }
    [data-type="shop"]:after{
        content: 'Butik'; // TODO: Another solution that is better to change language with
        color: $gray-light;
        position: absolute;
        right: 15px;
    }

    .items-content {
        border: 0;
    }

    .onethird {
        width: 28.3%; // 28.3 + 2.5 + 2.5 = 33.3%
        float: left;
        margin: 0 2.5% 12px;
        border-radius: 3px;
        p,
        h4 {
            position: relative;
            z-index: 2050;
            white-space: normal;
        }
        a img {
            width: auto;
            height: auto;
        }
        h4 a {
            color: $fm-black;
        }
    }
}


// Off canvas menu

.in > .navbar-nav {
    margin-top: 50px;

    li > a {
        text-align: left;
        /*padding-left: 40px; */

        ul > li > ul > li {
            .dropdown-header {
                text-align: center;
            }
        }
    }

    .mega-dropdown-menu .dropdown-header {
        text-align: center;
    }
}


@media screen and (min-width: $screen-sm + 1px) {

    .navbar .navbar-nav {
        display: inline-block;
        float: none;
        vertical-align: top;
    }

    .navbar-nav > li > .dropdown-menu {
        padding: 23px 50px 35px 50px;
    }
    #searchresults.navbar-nav > li > .dropdown-menu {
        padding: 23px 0px 35px 30px;
        margin-top: 0;
    }

}

@media screen and (max-width: $screen-sm) {

    .o-profile-section {
        top: 10px;
        z-index: 2000;
        padding-bottom: 0;

        &.fixed-theme {
            top: -3px;
        }
    }

    .o-profile__content {
    
        img,
        img.not-logged-in {
            border: 2px solid $body-color;
            box-shadow: 0 0 0 2px $brand-marine;
            height: 35px;
            width: 35px;
        }

        p {
            display: none;
        }

        &.in {
            img {
                border: 2px solid #fff;
            }
        }
    }
    
    .nav-profile {
        left: auto;
        right: -10px;
        top: 40px;
        padding-top: 0;
    }

    .navbar-header {
        position: absolute;
        left: 0;
        top: -48px;
    }

    .o-wishing-section {
        display: none;
    }

    .m-search-field__main {
        max-width: none;
        width: 100%;
        bottom: auto;
        margin-top: 5px;

        &.fixed-theme {
            display: none;
        }

    }

    .mega-dropdown-menu .dropdown-header {
        padding: 5px !important;
    }

    .divider-vertical {
        display: none;
    }

    .navbar-toggle {
        position: absolute;
        top: 12px;
        float: none;
        margin: 0;
    }

    .navbar-fixed-top.fixed-theme {
        padding-top: 18px;
        padding-bottom: 0px;
    }

    .main-logo.fixed-theme {
        margin-top: 0;
    }

    .backdrop {
        &.open {
            &.search-opened {
                display: none;
            }
        }
    }

    nav#header-searchresults.navbar {
        top: 58px;
        height: auto;
        margin-left: -15px;
        margin-right: -15px;
        //overflow-y: scroll;
    }
    .banner-loose #searchresults.open {
        display:none;
    }

    .onethird > a.item-image-container {
        height: 150px;
        line-height: 150px;
    }
    #searchresults .dropdown .dropdown-menu {
        overflow-y: scroll;
        top: 0;
        height: calc(100vh - 110px);
        position: relative;
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    background: black;
    z-index: 99;
    visibility: hidden;
    transition: opacity 0.5s; /* transition settings */
}

@media (min-width: $screen-sm) and (max-width: $screen-md - 1px) {

    .body-wrapper {
        padding-top: 150px !important;
    }
    .banner-loose .body-wrapper {
        padding-top: 105px !important;
    }

    .o-header__main.fixed-theme {
        padding-top: 0;
    }

    .main-logo.fixed-theme {
        margin-top: 22px;
    }

    .m-search-field__main {
        max-width: none;
        width: 100%;
        margin-bottom: 25px;
        margin-top: 15px;
        top: 72%;

        &.fixed-theme {
            display: none;
            &.search-open {
                display: block;
                background-color: $body-bg;
                height: 100%;
                margin-top: 28px;
                padding: 4px 15px 0px;
            }
        }
    }

    .navbar-offcanvas {
        float: left;
        margin-bottom: 15px;

        &.fixed-theme {
            margin-bottom: 0;
        }
    }

    .divider-vertical {
        left: -17%;
    }

    .o-profile-section {
        top: 51px;
        padding-bottom: 0;
        bottom: auto;
        z-index: 2050;

        &.fixed-theme {
            top: 51px;
        }
    }

    .o-wishing-section {
        bottom: auto;
        margin-top: 2px; 
    }

    .backdrop {

        &.open {
            top: 150px;
            display: block;
            &.search-opened {
                display: none;
            }
        }

        &.fixed-theme {
            top: 100px !important;
        }

        &.in {
            top: 149px;
            display: block;
        }

    }

    .navbar-nav>li>.dropdown-menu {
        margin-top: -10px;
    }
    nav#header-searchresults {
        top: 55px;
        .navbar-nav>li>.dropdown-menu {
            margin-top: 0;
            > li {
                overflow-y: scroll;
                top: 0;
                height: 436px; //100%;
                //height: calc(100vh - 110px);
            }
        }
        .onethird {
            a.item-image-container {
                height: 130px;
                line-height: 130px;
            }
        }
    }
    // .banner-loose #searchresults.open {
    //     display:none;
    // }
}
