.cat-items {

	&:after {
		content: "";
		flex: auto;
	}

	.cat-item {
		flex-grow: 0;
		position: relative;
		height: 150px;

		.cat-item-link{
			display: block;
			height: 100%;
			border: 1px solid $gray-light;
			border-radius: 3px;
			padding: 40px 5px;

		}

		.cat-name {
			display: block;
			color: $fm-black;
			font-weight: 500;
			letter-spacing: $spacing-small;//0.05em;
			margin: 0 0 10px;
		}
		
		.cat-amount {
			display: inline-block;
			color: $redesign-dark;
			font-weight: 500;
			border-bottom: 1px $redesign-dark solid;
			padding-bottom: 3px;
			margin: 0 0 23px;
		}

		&--sale {
			background: $fm-red-sale;
			height: 200px;
			border-radius: 3px;
			transition: background-color .3s ease;

			order: 15;

			.cat-item-link{
				border: 0;
				padding: 65px 5px;
			}
			.cat-name {
				font-size: 22px;
				font-weight: normal;
				color: $body-color;
			}
			.cat-amount {
				color: $body-color;
				font-weight: normal;
				border-bottom-color: $body-color;
			}

			&:hover {
				background-color: $fm-red-sale - 10;
				transition: background-color .3s ease;
			}
		}
	}
}

.o-category-box {
	position: relative;
	border-radius: 3px;
	padding: 15px 10px 25px 20px ;
	color: #ffffff;

	li:not(.different) {
		margin-right: 15px;
	}

	a {
		color: #fff !important;
		text-transform: uppercase;
		font-size: 12px;
		padding-bottom: 3px;

		svg {
			top: auto;
			bottom: 15px;
			left: 15px;
		}
	}

	h2,
	h3 {
		margin-bottom: 3px;
	}

	h2,
	h3,
	a {
		letter-spacing: 1px;
		font-weight: 400;
	}

	.o-list {
		width: 150%;
	}
}


.o-box--turkos {
	background-color: $brand-turkos;
	margin-right: 0;
	transition: ease-out .3s;

	&:hover {
		background-color: darken($brand-turkos, 3%);
	}
}

.o-box--marine {
	background-color: $brand-marine;
	transition: ease-out .3s;

	&:hover {
		background-color: darken($brand-marine, 3%);
	}
}

.o-box--orange {
	background-color: $brand-orange;
	transition: ease-out .3s;

	&:hover {
		background-color: darken($brand-orange, 3%);
	}
}

@media screen and (max-width: $screen-md) {

	.o-category-box {
		margin-top: 15px;
	}

	.cat-items {
		.cat-item {
			&--sale {
				order: 16;
			}
		}
	}

}

@media screen and (max-width: $screen-sm) {

	.cat-items {
		&:after {
			content: "";
			flex: none;
		}
		.cat-item {
			&--sale {
				order: 4;
			}
		}
	}

}