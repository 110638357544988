.t-top-row {
	margin-top: 15px;
	margin-bottom: 15px;
}

.t-semi-heading {
	font-size: 14px;
	letter-spacing: 2px;
	margin-bottom: 15px;
	font-weight: 500;

	&--caps {
		text-transform: uppercase;
	}
}

.t-heading-large {
	font-size: 26px;
	font-weight: 500;
}

.t-heading-medium {
	font-size: 22px;
	font-weight: 500;
}

.t-heading-smallmedium {
	font-size: 17px;
	line-height: 24px;
}

.t-heading-small {
	font-size: 13px;
	transition: .3s ease;
}

.t-heading--tertiaryHeading{
	font-size: 15px;
	font-style: italic;
	font-weight: 500;
	color: $base-dark;
}

.t-head-color--red {
	color: $fm-red;
}

.t-head-color--orange {
	color: $brand-orange;
}

.t-head-color--turkos {
	color: $brand-turkos;
}

.t-head-color--marine {
	color: $brand-marine;
}

a.t-head-color--red:hover {
	color: darken($fm-red, 10% );
}

a.t-head-color--orange:hover {
	color: darken($brand-orange, 10% );
}

a.t-head-color--turkos:hover {
	color: darken($brand-turkos, 10% );
}

@media only screen and (max-width: $screen-sm-max) {

	.t-semi-heading {
		font-size: 1.2rem;
		line-height: 2.4rem;
	}

	.t-heading-large {
		font-size: 2.8rem;
		line-height: 3.4rem;
	}

}

@media only screen and (max-width: $screen-xs-max) {
	.t-xs--center {
		text-align: center;
		.left,
		.right {
			text-align: center;
		}
	}
}