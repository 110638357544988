.o-shoe-sizes-container {
	display: block;
	margin-right: auto;
	margin-left: auto;
	width: 80%;
}

.o-shoe-sizes	{
	display: inline-block;
	margin-right: -15px; //auto;
	margin-left: auto;
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 15px;

		li {
			width: 40px;
			display: block;
			float: left;
			padding: 10px;
			margin-bottom: 15px;
			background-color: #fff;
			margin-right: 15px;
			border: 1px solid $base-dark;
			border-radius: 3px;
			cursor: pointer;
			font-weight: 500;
			line-height: 18px;
			font-size: 14px;
			text-align: center;
			padding: 10px;

			&:hover {
				background-color: $brand-turkos;
				color: #fff;
			}

			& input {
				display: none;
			}
			// &:nth-child(9n+9) {
			// 	margin-right: 0;
			// 	clear: right;
			// }
			
			// &.last-size {
			// 	margin-right: 0;
			// 	& + .last-size{
			// 		margin-right: 15px;
			// 	}
			// }

			&.added-size {
				background-color: $brand-turkos;
				color: #fff;
				border: 1px solid $base-dark;
			}

			&.extra-padding {
				padding: 10px 12px 10px 12px;
			}
		}
}

@media (min-width: $screen-md) and (max-width: $screen-lg) {

	.o-shoe-sizes-container {
		width: 100%;
	}

	// .o-shoe-sizes li {
	// 	margin-right: 3.1333%;
	// }

}

@media (min-width: $screen-sm) and (max-width: $screen-md) {

	// .o-shoe-sizes li {
	// 	padding: 14px;
	// 	margin-right: 3.355%;

	// 	&.extra-padding {
	// 			padding: 14px 16px 14px 16px;
	// 		}
	// }

}

@media screen and (max-width: $screen-sm) {

	// .o-shoe-sizes-container {
	// 	width: 100%;
	// }

	// .o-shoe-sizes li {
	// 	padding: 16px;
	// 	margin-right: 3.355%;

	// 	&:nth-child(9n+9) {
	// 		margin-right: 3.355%;
	// 		clear: none;
	// 	}

	// 	&.extra-padding {
	// 			padding: 16px 17px;
	// 		}
	// }

	// .o-shoe-sizes li.last-size {
	// 	margin-right: 0;
	// }

	// .o-shoe-sizes li.last-size + li.last-size {
	// 	margin-left: 3.355%;
	// }

}

@media screen and (max-width: $screen-xs) {

	.o-shoe-sizes-container {
		width: 100%;
	}

	// .o-shoe-sizes li{
	// 	padding: 15px;

	// 	&.extra-padding {
	// 			padding: 15px 16px;
	// 		}
	// }

	// .o-shoe-sizes li.last-size + li.last-size {
	// 	margin-left: 3.355%;
	// }

}