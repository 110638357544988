.s-active-follow-btn {
	background-color: $brand-marine;
	color: #fff;

	&:hover {
		color: #fff;
		background-color: $brand-marine;
	}
}

.s-inactive-category__small {
	font-weight: 400;
	color: $gray-blue-light;
}

.s-active-category__small {
	font-weight: 500;
	color: $base-dark;
}