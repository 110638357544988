.o-shoe-list {
	border: 1px solid $gray-light;
	border-radius: 3px;
}

.o-shoe-list__head {
	padding: 25px 25px 40px;
	border-bottom: 1px solid $gray-light;
	margin-bottom: 15px;
	line-height: 26px;

	h3 {
		font-size: 20px;
		line-height: 20px;
	}

	a {
		text-transform: uppercase;
		letter-spacing: 1px;
		line-height: 20px;
		font-weight: 500;
		margin-top: 1px;
	}
}

.o-shoe-list__content {
	padding: 0 15px;

	img {
		width: 75px;
		margin-right: 15px;
		height: 105px;
		object-fit: contain;
	}

	.o-flag {
		border-bottom: 1px solid $gray-lighter;
		height: 110px;

		&:last-child {
			border-bottom: none;
			padding-bottom: 5px;
		}
	}
}

.o-shoe-list__subtitle {
	margin-top: 16px;
	color: $gray-light;
	text-transform: uppercase;
	font-size: 14px;
}

.o-shoe-list__info {
	margin-bottom: -6px;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 1px;
}

.o-shoe-list__price {
	display: inline-block;
	font-weight: 500;
	margin-bottom: 0;
	margin-top: 5px;
	margin-right: 5px;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 1px;

	&--rea {
		text-decoration: line-through;
	}

	&--new-price {
		color: $brand-danger;
	}
}

.o-shoe-list__time {
	font-weight: 400;
	color: $gray;
	margin-right: 10px;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 1px;
}

.o-realisation-tag {
	position: absolute;
	border-radius: 20px;
	font-size: 12px;
	color: #fff;
	padding: 2px 8px;
	background-color: $brand-danger;
	margin-left: 7px;
	margin-top: 6px;
}


@media (min-width: $screen-md) and (max-width: $screen-lg)  {
	
	.o-realisation-tag {
		margin-left: 0;
		left: 55px;
	}

}

@media only screen and (max-width: $screen-md) {

	.o-shoe-list {
		margin-bottom: 25px;
	}
}

@media only screen and (max-width: $screen-sm) {

	.o-shoe-list__head {
		
		

		h3 {
			font-size: 18px;
			line-height: 24px;
		}
	}

}

@media screen and (max-width: $screen-xs) {

	.o-realisation-tag {
		margin-left: 0;
		left: 55px;
	}

}