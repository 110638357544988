// Footmall
// ------------------------------------------------------------ //

$fm-orange: #ffa73f;
$fm-teal: #57c7b3;
$fm-vintage: #e78042;
$fm-red: #ff685c;
$fm-black: #2b2b2b;
$fm-red-black-friday: #f74f4e;
$fm-red-sale:#F14958;

$fm-orange-light: lighten($fm-orange, 10%);
$fm-teal-light: lighten($fm-teal, 10%);
$fm-vintage-light: lighten($fm-vintage, 10%);
$fm-red-light: lighten($fm-red, 10%);
$fm-black-light: lighten($fm-black, 10%);

$fm-orange-dark: darken($fm-orange, 20%);
$fm-teal-dark: darken($fm-teal, 20%);
$fm-red-dark: darken($fm-red, 20%);
$fm-black-dark: darken($fm-black, 20%);

$gray: #777777;
//$gray-border: lighten(black, 80%) // #cccccc
$gray-border: lighten(black, 65%);
//$gray-light: lighten(black, 99.5%)
$gray-light: #dbdbdb;
$gray-inactive: #bcbcbc;

$blue-base: #052835;


// Redesign, not double-checked yet
// ------------------------------------------------------------ //

$redesign-gray:			#B2B2B2;
$redesign-dark:			#072934;
$redesign-darker:		darken($redesign-dark, 5%);
$redesign-orange:		#FD9259;
$redesign-turquoise:	#33CABC;
$redesign-pink:			#F9C1B7;
$redesign-orange-gradient-top: #F85D59;
$redesign-orange-gradient-bot: #FD8068;


// Shops
// ------------------------------------------------------------ //

$spartoo: #bc0044;
$footway: #643287;
$deichmann: #249760;
$sarenza: #ec1082;
$bonprix: #cb1030;
$conleys: #f5ba14;


// Filter
// ------------------------------------------------------------ //

$brown: #916b14;
$orange: $fm-orange;
$yellow: #fcd932;
$green: #00c166;
$blue: #3b93d8;
$pink: #ff19ce;
$purple: #b500a1;
$red: #f30023;
