/*
.carousel {
	overflow: hidden;
	background-color: #e8e8ea;
}


.carousel-inner > .item > img, 
.carousel-inner > .item > a > img {
	max-width: 800px;
	margin: auto;
}

.carousel-indicators {
	bottom: 10px;
}

.carousel-indicators li {
	width: 40px;
	height: 3px;
	border-radius: 0;
	background-color: rgba(#fff, .5);
	border: none;
}

.carousel-indicators .active {
    margin: 0;
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 0;
}

.carousel-caption {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    bottom: auto;
    z-index: 10;
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    text-align: center;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

    h3 {
    	font-size: 5rem;
    	margin-bottom: 3rem;
        color: white;

    }
}

.carousel-control {
	opacity: 0 !important;
}


@media (max-width: $screen-sm) {

    .carousel-caption {
        position: relative;

        h3 {
            font-size: 4rem;
        }
    }

    .carousel-inner > .item {
        height: 300px;
    }

    .carousel-inner > .item > img,
    .carousel-inner > .item > a > img {
        position: absolute;
        bottom: 0;
    }

}

*/

.startTopSearch {
	background-size: cover;
}

.startTopSearch.desktop{
		display: block;
		overflow: visible;
		height: 450px;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center center !important;
		padding-top: 160px;
	}
	
	.search-box{
		background: white;
		height: 100px;
		padding: 15px 45px 15px 45px;
		width: 660px;
		margin: 0 auto;
		border-radius: 100px;
		box-shadow: 10px 10px 30px rgba(0,0,0,0.15);
	}
	
	.search-input{
		background-image: url('/gfx/new/images/footmall-logo-small.svg');
		background-position: 0px center;
		background-size: 35px 35px;
		background-repeat: no-repeat;
		height: 100%;
		line-height: 29px !important;
		padding-left: 60px;
		outline: none;
		border: 0px;
		font-size: 1.7em;
		font-weight: 100 !important;
		width: 70%;
		padding-right: 0px;
		position: relative;
	}
	
	.search-location{
		width: 30%;
		display: inline-block;
		vertical-align: middle;
		color: #b6ccdf;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		position: relative;
	}
	
	.search-location .location-list{
		position: absolute;
		background: #ff9251;
		width: 100%;
		text-align: center;
		padding: 10px 5px 10px 5px;	
		border-radius: 0px 0px 5px 5px;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all 0.2s ease;                  
		-moz-transition: all 0.2s ease;                 
		-o-transition: all 0.2s ease;   
		-ms-transition: all 0.2s ease;          
		transition: all 0.2s ease;
		margin-top: 15px;
	}
	
	.location-current{
		text-align: right;
		margin-top: -8px;
	}	
	
	.location-current svg{
		vertical-align: middle;
		width: 17px;
		height: 17px;
		margin-top: -3px;
		margin-left: 5px;
	}
	
	.location-current svg path{
		fill: #b6ccdf;
	}
	
	.search-location:hover > .location-list{
		visibility: visible;
		opacity: 1;
		margin-top: 0px;
	}
	
	.search-location .location-list .location-item{
		color: white;
		font-size: 0.8em;
		border-bottom: 1px solid rgba(255,255,255,0.1);
		padding: 4px;
	}
	
	.search-location .location-list .location-item:last-child{
		border: none;
	}

	.text-below{
		text-align: center;
		margin-top: 20px;
		
		p,
		a {
			color: white;
			text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.2);
			font-size: 20px;
			letter-spacing: 1px;
			font-weight: 200;
		}
	}
	
	
	/* Mobil */
	
	.startTopSearch.mobile{	
		display: none;
	}
	
	@media(max-width: 768px){
		.startTopSearch.desktop{
			display: none;
		}
		.startTopSearch.mobile{	
			display: block;
		}
	}
	
	.startTopSearch.mobile .search-container{
		padding: 60px 5px 45px 5px;
		width: 450px;
		max-width: 100%;
		margin: 0 auto;
	}
	
	.startTopSearch.mobile .search-container .input-container{
		margin: 10px;
		padding: 0px 50px 0px 50px;
		background: white;
		border-radius: 30px;
		background-image: url('/gfx/new/images/footmall-logo-small.svg');
		background-position: 15px center;
		background-size: 25px 25px;
		background-repeat: no-repeat;
		height: 60px;
		box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
	}
	
	.startTopSearch.mobile .search-container .input-container.transperant{
		color: white;
		border: 2px solid white;
		background: none;
	}
	
	.startTopSearch.mobile .search-container .input-container input{
		border: 0px;
		outline: none;
		font-size: 1.2em;
		line-height: 60px;
		height: 60px;
		padding-right: 0px;
		padding-left: 0px;
		text-align: center;
		width: 100%;
	}
	
	.startTopSearch.mobile .search-location{
		width: 100%;
	}
	
	.startTopSearch.mobile .search-location .location-current{
		margin: 0px;
		text-align: center;
		height: 60px;
		line-height: 60px;
		color: white;
		text-shadow: 3px 3px 3px rgba(0,0,0,0.2);
	}
	
	.startTopSearch.mobile .search-location .location-current svg path{
		fill: white;
	}
	
	.startTopSearch.mobile .text-below{
		font-size: 0.7em;
		margin-right: 15px;
		margin-left: 15px;
	}
	
	
	/* Mobil END */
