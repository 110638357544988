@mixin shadow($depth) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);  
  }
}

@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

  @return 0 $primary-offset $blur $color;
}

@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

  @return 0 $primary-offset $blur $color;
}

@mixin gradient($args...) {
  background-image: -o-linear-gradient($args); 
  background-image: -moz-linear-gradient($args);
  background-image: -webkit-linear-gradient($args); 
  background-image: linear-gradient($args); 
}

@mixin icon($name, $type: 'svg') {
  background-image: url(#{$asset-path}/#{$name}.#{$type})
}
