.cc-grower {
	position: relative;
	z-index: 4000;
}

.body-wrapper {
	min-height: calc(100vh - 430px);
	padding-top: 129px;
}
.banner-loose .body-wrapper {
  padding-top: 110px;
}

.o-header__main {
	position: relative;
	text-align: center;
	background-color: #fff;
	padding-bottom: 10px;
}

.main-logo {
	position: relative;
    max-width: 200px;
    margin-bottom: 10px;
}

.o-topbar-container {
	position: relative;
	z-index: 1050;

	&.fixed-theme {
		visibility: hidden;
	}
}

.o-topbar {
	position: relative;
	height: 30px;

	&--left {
		float: left;
	}

	&--right {
		float: right;
	}

	&__text {
		font-size: 15px;
		line-height: 45px;
		font-weight: 400;
		letter-spacing: .25px;

		strong {
			font-weight: 500;
		}
		a {
			transition: none;
		}
	}

	ul > li {
		float: left;
		list-style-type: none;
		line-height: 45px;
		font-weight: 500;

		&:first-child {
			margin-right: 25px;
		}

		a {
			text-transform: uppercase;
			font-size: 13px;
			color: $blue-base;
			font-weight: bold;
			letter-spacing: 1px;
			font-weight: 500;
			transition: none;
		}
	}
}

.o-offer-banner {
	position: relative;
	text-align: center;
	height: 60px;

	p {
		display: inline-block;
		line-height: 60px;
		color: #fff;
		margin-right: 2rem;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		font-weight: 400;
		font-size: 16px;
	}

	&--btn {
		display: none;
	}

	#hide-offer {
		display: inline-block;
		position: absolute;
		top: 53%;
		right: 20px;
		transform: translateY(-50%);
	}
}
@media all and (min-width: 830px) {
	.o-offer-banner {
		&--btn {
			display: inline-block;
		}
	}
}
@media all and (max-width: 460px) {
	.o-offer-banner {
		p {
			font-size: 3.4vw;
		}
	}
}

@media (min-width: $screen-sm) and (max-width: $screen-md - 1px) {

	.o-header__main {
		padding-bottom: 0;
		padding-top: 10px;
	}

}


@media (max-width: $screen-sm) {

	.body-wrapper {
      padding-top: 110px;
  }
  .banner-loose .body-wrapper {
      padding-top: 70px;
  }

	.o-topbar-container {
		padding-bottom: 194px;
	}

	.o-topbar {
		width: 100%;
		text-align: center;
		height: auto;

		&__text {
			margin-top: 15px;
			line-height: normal;
		}

		&--left {
			float: none;
		}

		&--right {
			float: none;
			margin-top: 10px;
		}
	}

	.main-logo {
		width: 130px;
		margin-bottom: 10px;
		margin-top: 16px;
	}

	// .o-offer-banner {
	// 	height: auto;
	// 	padding: 15px;

	// 	p {
	// 		display: block;
	// 		line-height: 2.2rem;
	// 		margin-right: auto;
	// 		margin-bottom: 10px;
	// 	} 
	// }
}

@media screen and (max-width: $screen-xs) {

	.main-logo {
		margin-left: 0;
	}

}
