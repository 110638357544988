.o-insta-cards__feed {
	&.influencer {
		margin-bottom: 61.391px;
		&.influencer-feed__large {
			margin-bottom: 65.391px;
		}
	}
	.col-md-15 {
		width: 25%;
	}
}

.influencer {
	transition: width .5s ease;
	//margin-bottom: 12px;
}

.influencer-feed__large {
	width: 600px;
	transition: width .5s ease;

	.o-insta-card__image {
		height: 270px;
		transition: height .5s ease;
	}
}

.influencer-feed {
	transition: width .5s ease;
}

.o-insta-headline {
	color: $gray-light;
	font-size: .8em;
	letter-spacing: 1px;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 25px;

	a {
		color: $brand-marine;
		font-size: inherit;
	}
}

.o-insta-card {
	overflow: hidden;
	border-radius: 4px 4px 0 0;
	// border: 1px solid $gray-light;
	// > a {
	// 	display: inherit;
	// }
}

.o-flag__image {
	padding-right: 0;

	display: inline-block;
}

.o-insta-card__image {
	overflow: hidden;
	position: relative;
	transition: height .5s ease;
	padding-top: 100%; //Square 1:1 format

	img {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		object-fit: cover;
		object-position: bottom;
	}
}

.influencer-grid {
	.col-md-15 {
		padding-right: 15px;
		margin-top: 0;
	}

	.o-insta-card {
		margin-top: 44px;
	}

	.o-insta-card__image {
		background-color: $gray-light;
	
		img {
			height: 100%;
			object-fit: cover; //Should probably be :contain instead
		}
	}
}

.o-insta-card__content {
	display: block;
	position: relative;
	padding: 24px 15px 15px;
	border-left: 1px solid $gray-light;
	border-right: 1px solid $gray-light;
	border-bottom: 1px solid $gray-light;
	border-radius: 0 0 4px 4px;
	min-height: 94px; // 123px; When a third row of text (.text-gray-light) is used, use this instead.
}

.o-insta-card__content--text {
	//padding-right: 5px;
	font-weight: 500;
	display: inline-block;

	.o-insta-card__content--title {
		background-color: $body-color;
		display: inline;
		overflow: hidden;
	}

	p {
		font-size: 15px;
		white-space: nowrap;
	}

	small {
		font-size: 12px;
		margin-top: -3px;
		color: $gray-blue-light;
	}

	a {
		font-size: 14px;
		color: $link-color;

		&:hover {
			color: $link-hover-color;
		}
	}
}

.o-insta-card__round-image {
	position: absolute;
	top: -26px;
	left: 15px;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	border: 2px solid $body-color;
	background-color: $body-color;
	transition: ease-out .3s;

	&:hover {
		@include shadow(1);
	}
}

.o-insta-card__image--shoe {
	position: absolute;
	right: 15px;
	top: 30px;
	width: 55px;
	z-index: 200;

	// Below is a try to make all sizes of img's fit.
	top: 20px;
    width: 65px;
    height: 65px;
	object-fit: contain;
	//object-position: center;
}

.o-news-card {
	margin-top: 0;

	a.h3 {
		font-size: 22px;
		margin-right: 15px;
	}

	.btn {
		margin-left: 15px;
	}

	.o-insta-card__content {
		padding-left: 30px;
		padding-top: 24px;
		padding-bottom: 21px;

		a {
			color: $brand-marine;
			line-height: 1.3;
		}
	}
}

@media all and (max-width: 1250px) {
	.o-insta-cards__feed {
		&.influencer {
			margin-bottom: 113.391px;
			width: 420px;
			&.influencer-feed__large {
				margin-bottom: 75.391px;
				width: 540px;
			}
			.o-insta-headline {
				margin-bottom: 15px;
			}
		}
	}
}
@media all and (max-width: 1040px) {
	.o-insta-cards__feed {
		&.influencer {
			margin-bottom: 115.391px;
			.col-md-6 {
				width: 50%;
				float: left;
			}
			&.influencer-feed__large {
				margin-bottom: 77.391px;
				width: 270px;
				
				.col-md-6 {
					width: 100%;
					&:not(:first-child) {
						display: none;
					}
				}
			}
			.o-insta-headline {
				font-size: 0.7em;
			}
		}
	}
}
@media all and (max-width: 830px) {
	.o-insta-cards__feed {
		&.influencer {
			display: none;// Temporarily disable until responsive is done.

			width: 50%;
			margin-right: 0;
			&.influencer-feed__large {
				width: 33.3333%;
			}
		}
	}
}
@media all and (max-width: 720px) {
	.o-insta-cards__feed {
		&.influencer {
			width: 50%;
			margin-bottom: 14.797px;
			.col-md-6 {
				width: 100%;
				padding: 0 50px;
				&:not(:first-child) {
					display: none;
				}
			}
			&.influencer-feed__large {
				width: 33.3333%;
			}
		}
	}
}

@media (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
	
	.o-news-card {
		margin-top: 30px;
	}

}

@media (max-width: $screen-md) {

	.o-news-card {
		margin-top: 30px;
	}

}

@media (max-width: $screen-sm) {
	
	.o-insta-card {
		margin-top: 0;
	}

	.o-news-card .btn {
		margin-left: 0;
	}

}
