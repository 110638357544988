input[type="email"] {
    text-transform: none;
    letter-spacing: .25px;
    font-weight: 400;
    color: $gray-blue-light;
    font-size: 16px;
}

// Remove BS style
.form-control {
	box-shadow: none;
}

input[type="text"].search-form {
	&::-webkit-input-placeholder,
	&:-ms-input-placeholder ,
	&::-moz-placeholder {
        color: $redesign-gray;
    }
}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline: none !important;
    -webkit-appearance: none !important;
}

textarea,
input {
    outline: none;
    -webkit-appearance: none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.m-input-email-single {
	width: 100%;
	max-width: 470px;
	margin: 0 auto;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.m-input-email-single__input {
	width: 72%;
	padding: 15px 13px 16px 18px;
	line-height: 23px;
	border-radius: 4px 0 0 4px;
	text-align: left;
	font-weight: 100;
	border: none;
	background-color: #fff !important;

	&::-webkit-input-placeholder {
		padding-top: 2px;
	}
}

.m-input-email-single__btn {
	width: 28%;
	border-radius: 0 4px 4px 0;
	background-color: $brand-marine;
	border: none;
	color: #fff;
	transition: ease-out .3s;
	font-size: 14px;
	line-height: 32px;
	letter-spacing: 1.3px;
	text-transform: uppercase;
	font-weight: 400;
	padding: .8em 1em;

	&:hover {
		background-color: rgba($brand-marine, .7);
	}
}

// Login & Register inputs
.m-input__large {
	height: 55px;
	padding-left: 20px;
	border-color: $brand-marine;
	border-radius: 3px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
	font-size: 16px;
	box-shadow: 5px 5px 20px rgba(0, 0, 0, .05);

	&:hover {
		box-shadow: 5px 5px 20px rgba(0, 0, 0, .1);
	}

	&--modal {
		width: 100%;
	}
}

.m-input__max {
	height: 55px;
	padding-left: 20px;
	border-color: $brand-marine;
	border-radius: 3px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
	font-size: 16px;
	box-shadow: 5px 5px 20px rgba(0, 0, 0, .05);

	&:hover {
		box-shadow: 5px 5px 20px rgba(0, 0, 0, .1);
	}

	&--modal {
		width: 100%;
	}
}

.m-input__large,
.m-input__max {
	&::-webkit-input-placeholder {
	   color: $gray-light;
	   letter-spacing: 1px;
	}
}

.m-inner-label,
.m-password-label {
	position: absolute;
	right: 100px;
	//top: 17px;
	font-weight: 400;
	color: $gray-light;
	font-size: 16px;
	letter-spacing: .25px;
	
	&--right-30 {
		right: 30px;
	}
}

.m-btn-login__facebook {
	position: relative;
	border-radius: 3px;
	height: 55px;
	padding-left: 25px;
	padding-right: 25px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
	border-color: $color-facebook;
	text-align: left;
	line-height: 44px;
	box-shadow: 5px 5px 20px rgba(0, 0, 0, .05);
	transition: ease-out .3s;

	&:hover {
		box-shadow: 5px 5px 20px rgba(0, 0, 0, .1);
		background-color: $color-facebook;
		color: $body-color;
		transition: ease-out .3s;
		span,
		.facebook-letter {
			color: $body-color;
		}
		.facebook-letter path {
			fill: $body-color;
		}
	}

	span {
		color: $color-facebook;
		font-weight: 500;
	}

	> .facebook-letter {
		position: absolute;
		right: 25px;
		top: 50%;
		transform: translateY(-50%);
	}

	&--modal {
		width: 100%;
	}
}

.m-radio-btn-container {
	display: block;
	margin: 5px auto 20px auto;
	width: 80%;

	p {
		font-weight: 500;
		font-size: 17px;
	}

	&--modal {
		width: 100%;
	}

}

.m-checkbox-btn-container {
	display: block;
	margin: 5px auto 20px auto;
	width: 100%;

	p {
		font-weight: 500;
		font-size: 17px;
	}

	&--modal {
		width: 100%;
	}

}

.m-header-container-profilesettings{
	p {
		font-weight: 500;
		font-size: 17px;
	}
}

.m-input-container {
	width: 80%;
	margin-right: auto;
	margin-left: auto;
}

.m-input-half {
	width: 48%;

	> .m-input__large,
	> .m-input__max {
		width: 100%;
	}
}

.radio,
.checkbox {
	padding-left: 20px;
}

.radio label,
.checkbox label {
	display: inline-block;
	position: relative;
	padding-left: 10px;
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: .5px;
	line-height: 26px;
}

.radio label:before,
.checkbox label:before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 23px;
	height: 23px;
	left: 0;
	margin-left: -20px;
	border: 1px solid $base-dark;
	border-radius: 50%;
	background-color: #fff;
	transition: border 0.15s ease-in-out;
}

.radio label:after,
.checkbox label:after {
	display: inline-block;
	position: absolute;
	content: " ";
	width: 13px;
	height: 13px;
	left: 5px;
	top: 5px;
	margin-left: -20px;
	border-radius: 50%;
	background-color: #555555;
	transform: scale(0, 0);
	transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); 
}

.checkbox label {
	&:before,
	&:after {
		border-radius: 3px;
	}
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
	opacity: 0; 
}

.radio input[type="radio"]:focus + label::before,
.checkbox input[type="checkbox"]:focus + label::before {
	outline: none;
}

.radio input[type="radio"]:checked + label::after,
.checkbox input[type="checkbox"]:checked + label::after {
	transform: scale(1, 1); 
}

.radio input[type="radio"]:disabled + label,
.checkbox input[type="checkbox"]:disabled + label {
	opacity: 0.65; 
}

.radio.radio-inline,
.checkbox.checkbox-inline {
	margin-top: 15px; 
}

.radio-info input[type="radio"] + label::after,
.checkbox-info input[type="checkbox"] + label::after  {
	background-color: #5bc0de; 
}

.radio-info input[type="radio"]:checked + label::before,
.checkbox-info input[type="checkbox"]:checked + label::before {
	border-color: $base-dark; 
}

.radio-info input[type="radio"]:checked + label::after,
.checkbox-info input[type="checkbox"]:checked + label::after {
	background-color: $brand-turkos; 
}

.radio-info--red input[type="radio"]:checked + label::after,
.checkbox-info--red input[type="checkbox"]:checked + label::after {
	background-color: $brand-red !important; 
}

.radio-inline+.radio-inline,
.checkbox-inline+.checkbox-inline {
	margin-left: 30px;
}


@media (min-width: $screen-md) and (max-width: $screen-lg) {

	.m-btn-login__facebook,
	.m-radio-btn-container,
	.m-checkbox-btn-container,
	.m-input__large,
	.m-input__max {
		width: 100%;
	}

	.m-password-label {
		right: 40px ;
	}

	.m-input-container {
		width: 100%;
	}

}

@media (max-width: $screen-md) {

	.m-password-label {
		//right: 128px ;
	}

	.m-btn-login__facebook {
		text-align: center;
	}

}

@media (max-width: $screen-sm) {

	.m-input__large,
	.m-input__max {
		padding-left: 15px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		font-size: 16px;
	}

	.radio-inline.block-xs,
	.checkbox-inline.block-xs {
		margin-left: 0;
	}

	.m-btn-login__facebook {
		padding-left: 25px;
		width: 100%;
	}

	.m-radio-btn-container,
	.m-checkbox-btn-container {
		display: block;
		margin: 25px auto;
		width: 100%;
		text-align: center;

			p {
				font-weight: 500;
				font-size: 17px;
			}
	}

	.m-input-email-single__btn {
	    //font-size: 12px;
	}

	.m-input-email-single__input {
		//padding: 17px 13px 17px 18px;
	}

	.m-input-login__large::-webkit-input-placeholder {
		font-size: 14px;
	}

	.m-password-label {
		right: 45px ;
	}

	.m-input-container {
		width: 100%;
	}
	
	.m-input-half {
		width: 48.5%;
	}

}

@media (max-width: $screen-xs) {

	.radio,
	.checkbox {
		padding-left: 0;
	}

	.radio-inline + .radio-inline.block-xs,
	.checkbox-inline + .checkbox-inline.block-xs {
		margin-left: 0;
	}

	.m-password-label {
		right: 30px ;
	}

	.m-input-half {
		width: 100%;
		float: left !important;
	}

	.m-input-email-single__btn {
		width: 32%;
	}

	.m-input-email-single__input {
		width: 68%;
	}

}
