.o-flag {
    position: relative;
    display: inline-block;
    width: 100%;
}

.o-flag__image,
.o-flag__body {
    display: inline-block;
    //vertical-align: middle;

    .o-flag--top & {
        vertical-align: top;
    }

    .o-flag--bottom & {
        vertical-align: bottom;
    }

}

.o-flag div{
    //display: inherit;
    float: left;
}

.o-flag__body{
    width: 100%;

    & h3 {
        white-space: nowrap;
        overflow:hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
}
.o-flag .item-badges{
    text-align: center;
    left: initial;
    top: 50%;
    transform: translateY(-50%);
}


.o-flag__image {
    padding-right: 10px;

    + .o-flag__body {
        width: 60%;
        &--large{
            width: 80%;
        }
    }

    > img {
        display: block;
        max-width: none;
        max-height: 110px;
        overflow: hidden;
    }

    .o-flag--rev & {
        padding-right: 0;
        padding-left: 10px;
    }

}

@media only screen and (max-width: $screen-md) {

	.o-flag__image {
        + .o-flag__body {
            width: 70%;
        }
    }
}
@media screen and (max-width: $screen-xs) {

	.o-flag__image {
        + .o-flag__body {
            width: 60%;
        }
    }

}