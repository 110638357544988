// Observe that this is not built the same way as Bootstrap.
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-sm-15,
.col-md-15 {
    width: 20%;
}

.col-md-15--40 {
	width: 40%;
}

@media (width: $screen-lg) {
    
    .hidden-md-fix {
        display: none !important;
    }

}

@media (min-width: $screen-md) and (max-width: $screen-lg) {

    .col-sm-15 {
        width: 25%;
        &.h-5{
            display: none;
        }
    }
    .col-md-15 {
        width: 50%;
        margin-right: -15px;

        &:nth-child(even) {
            margin-right: 0;
            //float: right;
        }
    }

    .col-md-15--40 {
        width: 100%;
        margin-top: -15px;
    }

}

@media (min-width: $screen-sm) and (max-width: $screen-md-max) {

    .col-md-15 {
        width: 33.3333%;
        margin-top: 30px;
        margin-right: 0;

        &:nth-child(even) {
            margin-left: 0;

        }
    }

    .col-md-15--40 {
        margin-top: 0px;
        width: 100%;
    }

    .footer-main {
        .col-md-15:not(.footer-list__first) {
            width: 25%;
        }
    }

}

@media screen and (max-width: $screen-sm-max) {

    .col-sm-15 {
        width: 33.3333%;
        &.h-4,
        &.h-5{
            display: none;
        }
    }
    .col-md-15 {
        width: 50%;
        margin-top: 30px;
        //padding-right: 0;

        &:nth-child(odd) {
            padding-right: 15px;
        }
    }

}
@media screen and (max-width: 680px) {
    .col-sm-15 {
        width: 50%;
        &.h-4 {
            display: block;
        }
        &.h-5{
            display: none;
        }
    }
}

@media screen and (max-width: $screen-xs) {
    .col-sm-15 {
        width: 100%;
    }
    .col-md-15 {
        width: 100%;
        margin-top: 15px;
        padding-right: 15px;
    }

}