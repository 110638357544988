.footer-main {
	background-color: $brand-marine;
	color: $gray-light;
	margin-top: -1px;

	a {
		color: #fff !important;

		&:hover {
			color: rgba(#fff, .7) !important;
		}
	}

	h4 {
		color: rgba(#fff, 1);
	}

	article {
		color: rgba(#fff, .7);
	}
}

.footer-list__first > ul.footer-list {
	margin-left: 5px;
	margin-right: -50px;
}

.footer-list-head {
	color: rgba(#fff, .3);
	margin-bottom: 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.footer-list {
	margin-left: 75px;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: .75px;
	line-height: 1.6;

	li:not(:nth-child(1)) {
		margin-bottom: 7px;
	}
}

.logo-footmall-footer {
	max-width: 140px;
	margin-bottom: 15px;
}

.logo-sting {
	max-width: 75px;
	float: right;
	margin-top: 2px;
}

.footer-tiny {
	padding-top: 15px;
	padding-bottom: 15px;
	background: #12333f;

	small {
		color: rgba($gray-lighter, .7);
	}
}

.backed-by {
	float: right;
	
	small {
		margin-top: 2px;
		width: 100%;
	}
}


@media (max-width: $screen-lg) {

	.footer-list,
	.footer-list__first > ul.footer-list {
		margin-left: 0;
	}

	.footer-tiny,
	.footer-main {
		text-align: center;

		article {
			// text-align: justify;
			// text-align-last: center;
			margin-top: 25px;
		}
	}

	.footer-main h4 {
		margin-top: 30px;
	}

	.footer-list-head {
		margin-top: 25px;
	}

	.backed-by {
		float: none;

		small {
			display: block;
		}
	}

	.logo-sting {
		float: none;
	}

	.icon-social-media {
		
	}

	.icon-circle {
		left: 0;
	}

}

@media (min-width: $screen-sm) and (max-width: $screen-lg) {

	.col-md-15:first-child ul.footer-list {
		margin-right: 0;
	}

	.footer-list__first {
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
		clear: both;
	}

	.footer-list--alignfix {
		margin-bottom: 32px;
	}

}

@media (max-width: $screen-sm) {

	.footer-list__first > ul.footer-list {
		margin-right: 0;
	}

	.footer-list__first {
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
		clear: both;
	}

	.footer-list--alignfix {
		margin-bottom: 32px;
	}

}


