$onesixthWidth: 190px;
$onesixthMargin: 12px;

.onesixth{
  flex: 0 0 $onesixthWidth;
  margin: 0 0 $onesixthMargin $onesixthMargin*5+2;
  transition: all .5s ease;
  max-width: $onesixthWidth;
  .item-image-container{
    width: 124px;
    height: 180px;

    & img {
      width: 150px;
      max-width: none;
    }
  }

  & h4 {
    margin: 1.4em 0 0.2em;
  }

  & p{
    margin: 0 0 0.1em 0;
  }

  &.brand {
    height: 230px;
    width: 230px;
    margin-bottom: 22.5px;
    margin-left: 22.5px; // TODO: media-query(1040 -> 1250) set to 39.3px
    //252.5*4=1010px
  }
}

.onethreeth{
  flex: 0 0 215px;
  margin: 0px 0 $onesixthMargin $onesixthMargin*10;

  .item-image-container{
    width: 180px;
    height: 220px;

    & img{
      max-width: 100%;
      width: inherit;
    }

  }

  & h4 {
    margin: 2em 0 0.2em;
  }
  & p {
    margin: 0 0 0.1em 0;
  }
}
.modal-wrapper-item{
  & .item-related .onesixth,
  & .influencer-item{
    width:23.84%;
    flex: 0 0 23.84%;
    &:nth-child(even) {
      //margin-left: initial;
    }
  }

  & .onesixth h4{
    margin-top: 2.4em;
  }
}

@media all and (max-width: 1250px){
  .onesixth{
    margin: 0 0 $onesixthMargin $onesixthMargin;
  }
  .onethreeth{
    margin: 0px 0 $onesixthMargin $onesixthMargin*4.2;
  }
}
@media all and (max-width: 1040px){
  .onesixth{
    margin: 0 0 $onesixthMargin $onesixthMargin;
  }
  .onethreeth{
    margin: 0px 0 $onesixthMargin $onesixthMargin*7.333;
  }
}
@media all and (max-width: 830px) {
  .onesixth{
    width: 21%;
    flex: 0 0 21%;
    max-width: 21%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .onethreeth{
    width: 29.333333%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .influencer-modal-related {
    .item-footer {
      width: initial;
    }
  }
  .modal-wrapper-item{
    & .item-related .onesixth {
      width:48%;
      flex: 0 0 48%;
      max-width: 48%;
      &:nth-child(even) {
        margin-left: 0;
      }
    }
    & .influencer-item {
      width:48.8%;
      &:nth-child(odd) {
        margin-left: 0;
      }
    }
  }
}
@media all and (max-width: 667px) {
  .onesixth{
    width: 29.333333%;
    flex: 0 0 29.3333%;
    max-width: 29.3333%;
  }
  .onethreeth{
    width: 46%;
    flex: 0 0 46%;
    max-width: 46%;
  }
}

@media all and (max-width: 567px) {
  .onesixth {
    width: 46%;
    flex: 0 0 46%;
  }
  .onethreeth{
    width: 96%;
    flex: 0 0 96%;
  }
  .modal-wrapper-item {
    & .onesixth{
      width:100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    & .influencer-item {
      width:48%;
      flex: 0 0 48%;
      max-width: 48%;
    }
  }
}
@media all and (max-width: 350px) {
  .modal-wrapper-item{
    & .item-related .onesixth {
      width:100%;
      flex: 0 0 100%;
      margin: 0;
      max-width: 100%;
    }
    & .influencer-item {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      margin: 0 0 12px 0;
    }
  }
}

.pagination{
  .btn {
    padding-top: 0.6em;
    letter-spacing: 0.05em;
  }
}

.brand {
  border: 1px solid $gray-light;
}
.brand-center {
  text-align:center;
  left: 0;
  right: 0;
}

.brand-name-link {
  display: block;
  color: $fm-black;
  font-weight: 500;
  letter-spacing: $spacing-small;//0.05em;
  margin: 0 0 10px;
}

.brand-amount-link {
  display: inline-block;
  color: $redesign-dark;
  font-weight: 500;
  border-bottom: 1px $redesign-dark solid;
  padding-bottom: 3px;
  margin: 0 0 23px;
}
.btn-follow {
  position: relative;
  padding: 0.75em 1em !important;
  padding-left: 2.5em !important;
  transition: width .3s ease-in-out, border-color .3s ease-in-out; //background-color .3s ease-in-out

  &:before {
    content: "";
    z-index: 60;
    border-color: $redesign-gray;
    color: $redesign-dark;
    @include icon('icons/add-blue');

    position: absolute;
    left: 12px;
    height: 13px;
    top: 11px;
    width: 12px;
    background-size: 16px;
    background-position: center;
    transition: transform .3s ease-in-out;
  }

  &.active {
    background-color: $redesign-dark;
    color: $body-color;
    border: 1px solid rgba($brand-marine, .2);
    animation: scale-heart 600ms ease;

    &:before {
      transform: rotate(45deg);
      transition: transform .3s ease-in-out;
      @include icon('icons/add-white');
    }

    &:hover {
      background-color: rgba($redesign-dark, 0.75);
      color: $body-color;
      //transition: background-color .3s ease-in-out;
    }
  }
}

.following {
  .btn-follow:before {
    background-color: $redesign-dark;
    border-color: $redesign-dark;
    color: $body-color;
    background-image: none;
    padding-left: 1.6em;
    transform: rotate(45deg);
  }
  .m-follow-btn {
    &:hover {
      color: rgba($redesign-dark,1);
      border: 1px solid rgba($redesign-dark, 1);
      background-color: rgba($body-color, 0.75);
    }
    &--white:before {
      color: $body-color;
      border-color: $body-color;

      &:hover {
        color: $body-color;
        background-color: $brand-marine;
      }
    }
  }
}

.items-heading{
  margin-left:0px ;
  padding:0 15px;
  .items-header {
    margin-bottom: 10px;
    font-weight: 500;
  }
  .items-heading--title {
    order: 1;
    flex: 0 1 100%;
    text-align: center;
    h1,
    h2,
    h3,
    p {
      display: inline;
    }
    img {
      display: inline;
      float: right;
      width: 100%;
      margin-bottom: 15px;
      height:40px;
	    object-fit: contain;
    }
  }
  .items-heading--subtitle{
    order: 2;
    flex: 1 1 100%;
    text-align: center;
    margin-bottom: 15px;
  }
  .items-heading--watch{
    order: 3;
    flex: 0 0 50%;
    text-align: right;
    margin-bottom: 0;
    > a {
      margin-right: 10px;
    }
    &.empty {
      display: none;
    }
  }
  .items-heading--filter{
    order: 4;
    flex: 0 0 50%;
    text-align: left;
    &.center {
      flex: 0 0 100%;
      text-align: center;
    }
    .fm-dropdown {
      margin-left: 10px;
    }
  }
  .items-num {
    font-weight: 500;
    padding: 0;
    text-transform: lowercase;
    h2 {
      font-size: 15px;
      font-weight: 500;
      color: $redesign-dark;
    }
  }
  .btn-alert {
    padding: 0.8em 1.2em 0.8em 3.8em;
  }
  .fm-dropdown {
    button {
      margin-bottom: 0px;
      color: $redesign-dark;
      border: 1px solid $redesign-gray;
      letter-spacing: 0.1em;
      font-weight: 500;
      padding: 0.68em 1.5em 0.5em 1.2em;
  
      &:after{
        content:url('../../gfx/icons/dropdown-blue.svg');
        width: 24px;
      }
    }
  
    &.fm-dropdown-open button:after{
      content: url('../../gfx/icons/dropdown-up-blue.svg')!important;
    }
  }
  .fm-dropdown-content {
    border: 1px solid $redesign-gray;
    border-top: none;
  }
}

.items-heading-top{
  padding: 15px 15px 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: baseline;//center;
}

@media all and (min-width: 830px) {
  .items-heading{
    padding: 0 0 0 30px;

    .items-heading--title {
      position: relative;
      order: 1;
      flex: 0 1 50%;
      text-align: left;
      img {
        width: 130px;
        margin-bottom: 0;
        height: 70px;
        position: absolute;
        right: 0;
      }
    }
    .items-heading--subtitle{
      order: 3;
      flex: 1 1 50%;
      text-align: left;
      margin-bottom: 0;
    }
    .items-heading--watch{
      order: 2;
      flex: 0 0 auto;
      text-align: center;
      margin-bottom: 10px;
      > a {
        margin-right: 0;
      }
      &.empty {
        display: initial;
        flex-basis: 1px;
      }
    }
    .items-heading--filter{
      order: 4;
      flex: 0 0 auto;
      &.center {
        flex: 0 0 auto;
        text-align: left;
      }
      .fm-dropdown {
        margin-left: 0;
      }
    }
    .fm-dropdown {
      button {
        text-align: right;
        border: 1px solid transparent;
        padding: 0.68em 0em 0.5em 1.2em;
      }
      &.fm-dropdown-open {
        button {
          //border: 1px solid $gray-lighter;
          border-color: transparent !important;
        }
      }
      .fm-dropdown-content {
        //border: 1px solid $gray-lighter;
        border: 0 !important;
        border-top: none;
      }
    }
  }
  .items-heading-top{
    padding: 30px 0px 10px;
    border-bottom: 1px solid $gray-lighter;
  }
} // Media min 830px end

#inputGroupSuccess1::-webkit-input-placeholder, #inputGroupSuccess1{
  font-size: 14px;
}

@media all and (max-width: 400px) {
  .items-heading{
    .items-heading--watch{
      flex: 0 0 100%;
      text-align: center;
      margin-bottom: 15px;
      > a {
        margin-right: 0;
      }
    }
    .items-heading--filter{
      flex: 0 0 100%;
      text-align: center;
      .fm-dropdown {
        margin-left: 0;
      }
    }
  }
}// Media max 400px end

.items-row-count{
  float: left;
  min-width: 65px;
  overflow:hidden;
  margin-right:30px;
  margin-top: 3px;
}
.items-row-count-btn{
  display: inline-block;
  background-size: cover!important;
  background-position: center!important;;
  width:25px;
  height:25px;
  cursor: pointer;
  opacity: 1;
  transition: opacity .3s ease;

  &:hover {
    opacity: .75;
    transition: opacity .3s ease;
  }
}
.row-4{
  background: url('/gfx/icons/flow-more.svg');
  float: left;
}
.row-3{
  background: url('/gfx/icons/flow-less.svg');
  float: right;
}
.row-4.checked-count{background: url('/gfx/icons/flow-more-filled.svg');}
.row-3.checked-count{background: url('/gfx/icons/flow-less-filled.svg');}

.items-heading-footer{
  margin-top: 20px;

  & .items-heading-sorting{
    margin-top: 10px;
  }
  h5,
  .filter-search-tags li:first-of-type {
    font-size: 14px;
    font-weight: 500;
    color: $redesign-dark;
    text-transform: uppercase;
  }
}

.item-badges {
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  z-index: 100;
  
  .item-badge {
    background: #062834;
    display: inline-block;
    color: white;
    width: 42px; //42+4+4=50px;
    height: 42px;
    border-radius: 50%;
    border: 4px solid white;
    line-height: 44px;
    letter-spacing: 0.05em;
    z-index: 100;
    text-transform: uppercase;
    font-size: 0.8em;
    padding: 0px;
    box-sizing: content-box;
    
    &.item-badge-sale {
      background: $fm-red;
      z-index: 120;
      @include gradient(0deg, $redesign-orange-gradient-bot, $redesign-orange-gradient-top);
    }

    & + .item-badge {
      margin-left: -18px;
    }
  }
}

.item-image-container{
  margin-top: 17px;
}

.item-brand-link {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  color: $redesign-gray;
}
a.item-brand-link:not([href]){
  color: $redesign-gray;
}

.item-name-link {
  display: inline-block;
  width: 100%;
  margin: 5px 0;
  font-size: 16px;
  font-weight: 500;
  color: $redesign-dark;
}

.item {
  
  p {
    width: 100%;
    line-height: normal;
    color: $redesign-dark;
    margin: 0 0 $font-small 0;
    font-family: "proxima nova", $font-family-base;
  }

  .btn-alert {
    background-color: $body-color;
    width: 100%;
    max-width: 170px;
    transition: visibility 0s, background-color .3s ease;
  }

  &:hover {
    .btn-alert {
      visibility: visible;
    }
    .item-shop-image {
      visibility: hidden;
    }
  }
  @media screen and (max-width: $screen-sm) {
    .btn-alert {
      visibility: visible;
    }
    .item-shop-image {
      visibility: hidden;
    }
  }
  &.faved .icon-heart {
    color: $redesign-dark;
    opacity: 1;
    @include icon('icons/heart-blue-filled');
    animation: scale-heart 600ms ease;
    &:hover {
      opacity: 0.75;
    }
  }
}
.no-svg .item.faved .icon-heart{
  @include icon('favourite-active', 'png');
}
@keyframes scale-heart {
  0%    { transform: scale(1); }
  20%   { transform: scale(0.1); }
  80%   { transform: scale(1.1); }
  100%  { transform: scale(1); }
}

.items-footer {
  .filter-body-text {
    padding: 20px 15px 0px;
    &:first-of-type {
      border-top: 1px solid $gray-light;
      margin-top: 30px;
    }
  }
}

/* Icons */
.fm-input-icon-magnifier,
.row-items .icon-magnifier {
  background-size: 2em;
  background-position: 15px center;
  @include icon('icons/search-gray');
  
  &:hover {
    color: $redesign-dark;
    opacity: 0.75;
    transition: .3s ease;
    @include icon('icons/search-blue');
  }
}
.row-items .icon-heart{
  color: $redesign-dark;
  background-size: 1.8em;
  @include icon('icons/heart-blue');

  &:hover {
    @include icon('icons/heart-blue-filled');
    opacity: 0.75;
    transition: .3s ease;
  }
}

.price {
  white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;
}

.price-sale {
  font-weight: 500;
  color: $fm-red;
  margin-right: 1em;
  font-family: "proxima nova", $font-family-base;
}
.price-ord {
  text-decoration: line-through;
  font-family: "proxima nova", $font-family-base;
}

.pricealerts-active{
  .btn-alert {
    visibility: visible;
  }
  .item-shop-image {
    visibility: hidden;
  }
}

.item-footer {
  display: inline-block;
  width: 100%;

  .icon {
    font-weight: 500;
    color: $redesign-gray;
    font-size: $font-small;
    letter-spacing: $spacing-small;
    padding: 1em 1em 1em $font-small*4-.5em;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: .5em center;
    opacity: 1;
    width: auto;
    height: auto;

    &:hover {
      opacity: .9;
      color: $redesign-dark;
    }
  }

  .item-footer-pricealertbox {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0 auto;
    
    //.btn-alert,
    .item-shop-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    .item-shop-image {
      height: 40px;
      object-fit: contain;
      z-index: 50;
      line-height: 32px;
      max-width: 50%;
      margin: 0 auto;
    }
  }
}
.img-par{
  position: relative;
}

.shop-img{
  position: absolute;
  right: -7%;
  height: 85px!important;
}

@media all and (max-width: 1040px){
  
}
@media all and (max-width: 830px){
  .items-heading-footer {
    margin-top: 0;
    margin-bottom: 20px;

    h5,
    .filter-search-tags li:first-of-type {
      font-size: 14px;
      font-weight: 500;
      color: $redesign-gray;
      text-align: center;
      width: 100%;
    }
  }

  .img-par {
    position: static;
  }
  .shop-img{
    position: static;
  }
}
@media all and (max-width: 400px){
  .onesixth:not(.onethreeth) .item-footer .item-footer-pricealertbox {
    width: 100%;
  }
}