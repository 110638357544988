.o-section {
	padding-top: 50px;
	padding-bottom: 50px;
}

.o-section__large {
	padding-top: 100px;
	padding-bottom: 100px;
}

.o-section--m-15 {
	margin-left: 15px;
	margin-right: 15px;
}

.o-section--m-30 {
	margin-left: 30px;
	margin-right: 30px;
}

.o-section--p-15 {
	padding-left: 15px;
	padding-right: 15px;
}

.o-section--pl-30 {
	padding-left: 30px;
}

.o-section--pt-0 {
	padding-top: 0;
}

.o-section--background-brands {
	background-size: cover;
}

.o-section--orange {
	background-color: $brand-orange;
}

@media (max-width: $screen-sm) {

	.o-section {
		padding-top: 35px;
		padding-bottom: 35px;
	}

	.o-section--xs-pad {
		padding-left: 30px;
		padding-right: 30px;
	}

}
