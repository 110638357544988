.btn {
	text-transform: uppercase;
	font-weight: 500;
	background-color: transparent;

	&:hover {
		background-color: transparent;
	}
	&.btn-white {
		background-color: $body-color;
	}
}

.btn-row {
	.btn + .btn {
		margin-left: 20px;
	}
}

.btn-medium {
    padding: .8em 1.5em;
    &.btn-icon {
    	padding-left: 4em;
    }
}

.btn-alert {
	z-index: 60;
	visibility: hidden;
	background-color: $body-color;
	border-color: $redesign-gray;
	color: $redesign-dark;
	background-size: 2em;
	@include icon('icons/off-blue');
	
	&.active {
	  visibility: visible;
	  background-color: $redesign-dark;
	  border-color: $redesign-dark;
	  color: $body-color;
	  @include icon('icons/on-white');

	  + .item-shop-image {
		visibility: hidden;
	  }
	}
  
	&.btn:hover {
	  visibility: visible;
	  background-color: $redesign-dark;
	  border-color: $redesign-dark;
	  color: $body-color;
	  transition: background-color .3s ease;
	  @include icon('icons/off-white');
	  
	  &.active {
		visibility: visible;
		background-color: $body-color;
		color: $redesign-dark;
		@include icon('icons/on-blue');
	  }
	}
}

.m-btn-sale {
	visibility: visible;
	border: none;
	color: $body-color;
	background-color: $fm-red;
	background-size: 2em;
	@include icon('icons/off-white');

	&.btn:hover {
		color: $body-color;
		border: none;
		background-color: $redesign-orange-gradient-bot;
		transition: background-color .3s ease;
	}
}

.m-btn-sale--red {
	border-color: $fm-red-sale;
	color: $fm-red-sale;
	@include icon('icons/off-red');

	&.btn:hover {
		border-color: $fm-red-sale;
		color: $body-color;
		background-color: $fm-red-sale;
		transition: background-color .3s ease;
		@include icon('icons/off-white');
		&.active{
			color: $body-color;
		}
	}
}

.m-btn-shops {
	visibility: visible;
	border: none;
	color: $body-color;
	background-color: $redesign-dark;
	background-size: 2em;
	@include icon('icons/off-white');

	// &.active {
	// 	visibility: visible;
	// 	background-color: $redesign-dark;
	// 	border-color: $redesign-dark;
	// 	color: $body-color;
	// 	@include icon('icons/on-white');
	// }

	&.btn:hover {
		color: $body-color;
		border: none;
		background-color: $redesign-darker;
		transition: background-color .3s ease;
	}
}

.m-btn-round {
	border-radius: 50px;
	border: 1px solid currentcolor;
	padding-left: 25px;
	padding-right: 25px;
}

.m-btn--marine-border {
	border: 1px solid $redesign-dark;
}

.m-btn--orange {
	color: $body-color;
	background-color: $redesign-orange;
	transition: ease-out .3s;
	letter-spacing: 2px;
	font-weight: 400;
	text-transform: uppercase;

	svg path {
		fill: $body-color;
	}

	&:hover {
		color: $body-color;
		background-color: $redesign-orange;
	}
}

.m-btn--white {
	border: 1px solid rgba(#fff, .6);
	color: #fff;
	transition: ease-out .3s;
	letter-spacing: 2px;
	font-size: 12px;
	padding: 8px 22px;
	font-weight: 400;

	&:hover {
		color: #fff;
		border: 1px solid rgba(#fff, 1);
	}
}

.m-btn--gray {
	border: 1px solid $gray-light;
	color: $gray-light;
	transition: ease-out .3s;

	&:hover {
		color: rgba(#fff,.7);
		border: 1px solid rgba(#fff, .7);
	}
}

.m-btn--gray-no-border {
	border: 0;
	color: $gray-light;
	transition: ease-out .3s;

	&:hover {
		color: $gray-dark;
		border: 0;
	}
}

.m-btn--no-border {
	border: 0;
	&:hover {
		border: 0;
	}
}

.m-follow-btn {
	padding: 1em 1.5em;
	color: $brand-marine;
	border: 1px solid rgba($brand-marine, .2);
	line-height: 1.4em;

	&:hover {
		color: rgba($brand-marine,1);
		border: 1px solid rgba($brand-marine, 1);
	}

	&--white {
		color: $body-color;
		border-color: $body-color;

		&:before {
			@include icon('icons/add-white');
		}

		&:hover {
			color: $body-color;
			background-color: $brand-marine;
		}
	}
}

.m-btn--marine-text {
	color: $brand-marine;
	padding: 8px 25px;

	&:hover {
		color: rgba($brand-marine, .7);
		border: 1px solid rgba($brand-marine, .5);
	}
}

.m-btn--full_white {
	@extend .m-btn-round;
	@extend .m-btn--white;
	@include shadow(0);

	background-color: rgba(#fff, 1);
	color: $brand-turkos;
	text-transform: uppercase;
	font-size: 20px;
	padding: 8px 35px;
	padding-top: 11px;

	&:hover {
		@include shadow(5);
		background-color: rgba(#fff, .9);
		color: $brand-turkos;
	}
}

.m-btn-light-marine {
	@extend .m-follow-btn;
	font-size: 13px;
	letter-spacing: .75px;
	padding: 10px 25px 8px 25px;
}

.m-btn-rectangle {
	border-radius: 4px;
	padding: 17px 40px;
	transition: ease-out .3s;

	&--orange {
		background-color: $brand-orange;
		color: #fff !important;
		letter-spacing: .75px;
		box-shadow: 0 1px 3px rgba($brand-orange,0.12), 0 1px 2px rgba($brand-orange,0.24);
 		transition: all 0.3s cubic-bezier(.25,.8,.25,1);

		&:hover {
			background-color: darken($brand-orange, 2.5%);
			color: #fff !important;
			box-shadow: 0 14px 28px rgba($brand-orange,0.25), 0 10px 10px rgba($brand-orange,0.22);
		}
	}

	&--red {
		background-color: $brand-red;
		border: 0;
		color: #fff !important;
		letter-spacing: .75px;
		box-shadow: 0 1px 3px rgba($brand-red,0.12), 0 1px 2px rgba($brand-red,0.24);
 		transition: all 0.3s cubic-bezier(.25,.8,.25,1);

		&:hover {
			background-color: darken($brand-red, 5%);
			color: #fff !important;
			box-shadow: 0 14px 28px rgba($brand-red,0.25), 0 10px 10px rgba($brand-red,0.22);
		}
	}
}

.m-btn-rectangle-2 {
	border-radius: 4px;
	padding: 17px 0;
	width: 100%;
	transition: ease-out .3s;

	&--orange {
		background-color: $brand-orange;
		color: #fff !important;
		letter-spacing: .75px;

		&:hover {
			background-color: darken($brand-orange, 5%);
			color: #fff !important;
		}
	}
}

.m-btn-cthru {
	background-color: rgba(255,255,255,0.2);
}

.m-btn-fullwidth {
	width: 100%;
}

.m-btn-visible {
	visibility: visible !important;
}

//Cookie consent button
.cc-btn {
	border-radius: 20px;
}

.nav-tabs {
	border-bottom: none !important;
}

.nav-tabs > li, .nav-pills > li {
    float:none;
    display:inline-block;
}

.nav-tabs, .nav-pills {
    text-align:center;
}

.nav-tabs > li > a {
	font-weight: 500;
	color: $gray-blue-light;
	margin-right: 0 !important;
	line-height: inherit !important;
	border: none !important;
	border-radius: 0 !important;
	padding: 0;
	
	&:hover {
		background: none !important;
		border: none !important;
	}
}

.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:hover, 
.nav-tabs > li.active > a:focus {
	background: #fff !important;
	border: none;
	font-weight: 500;
	color: $base-dark;
}

@media (max-width: 768px) {
  .btn-responsive {
    padding:2px 4px;
    font-size:80%;
    line-height: 1;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .btn-responsive {
    padding:4px 9px;
    font-size:90%;
    line-height: 1.2;
  }
}

@media (max-width: 500px) {
	.btn-row {
		.btn + .btn {
			display: block;
    		margin: 15px auto 0;
		}
	}
}


.pagination a.transparent {
	background: #fff;
	color:#062834!important;
	border-color: #ccc!important;
}
// OLD STYLES FIX
