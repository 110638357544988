.cta-blocks {
    display: inline-block;
    width: 100%;

    + .cta-blocks:not(.cta-blocks--noMargin) {
        margin-top: 30px;
    }

    &--lg {
        .cta-block > a.cta-block-bg {
            height: 227px;
            padding: 80px 15px;
        }
        .cta-block > .cta-block-bg{
            padding: 60px 15px;
        }
    }
    &--md {
        .cta-block > .cta-block-bg {
            padding: 40px 15px;
        }
    }
    &--sm {
        .cta-block > .cta-block-bg {
            padding: 25px 15px;
        }
    }

    &--largerFont {
        .cta-block-name {
            font-size: 24px;
        }
        .cta-block-amount {
            font-size: 16px;
        }
    }
    &--smallerFont {
        .cta-block-name {
            font-size: 20px;
            margin: 0;
        }
        .cta-block-amount {
            font-size: 12px;
        }
    }

    .cta-block {
        > .cta-block-bg {
            display: block;
            background-color: $brand-marine;
            background-size: cover;
            background-position: center center;
            border-radius: 3px;

            &.brand,
            &.shop {
                background-color: transparent;
                &:hover {
                    background-color: transparent;
                }
            }
            &.brand {
                height: 230px;
                .cta-block-name,
                .cta-block-amount {
                    font-weight: 500;
                }
            }
            &.brand-auto {
                height: auto;
            }
            &.shop {
                height: auto;
                .cta-block-img img {
                    width: 100%;
                }
            }
            div:last-child {
                margin-bottom: 0;
                p {
                    margin-bottom: 0;
                }
            }
            &:hover {
                background-color: $brand-marine + 10;
            }
        }
        &.col-sm-15 {
            margin-top: 30px;
        }
    }
}

.cta-block-img {
    margin: 0 auto;
    max-width: 120px;
    img {
        height: 50px;
        object-fit: contain;
    }
}

.cta-block-name {
    display: block;
    color: $fm-black;
    font-weight: normal;
    letter-spacing: $spacing-small;//0.05em;
    margin: 0 0 10px;

    &--white {
        color: $body-color;
    }
}

.cta-block-amount {
    display: inline-block;
    color: $redesign-dark;
    font-weight: normal;
    text-transform: uppercase;
    border-bottom: 1px $redesign-dark solid;
    padding-bottom: 0;
    margin: 0 0 20px;

    &--white {
        color: $body-color;
        border-bottom-color: $body-color;
    }
}

.cta-block {
    .cta-block-button {
        transition: .3s ease color, .3s ease border-color, .3s ease background-color;

        &--white {
            color: $body-color;
            border-color: $body-color;
            background-color: transparent;

            &:hover {
                color: $brand-marine;
                border-color: $body-color;
                background-color: $body-color;

                &::before {
                    @include icon('icons/add-blue');
                }
            }
        }

        &:hover {
            transition: .3s ease color, .3s ease border-color, .3s ease background-color;
        }
        &.active {
            &:hover {
                &::before {
                    @include icon('icons/add-white');
                }
            }
        }
    }
}

@media screen and (max-width: 987px){
    .cta-blocks {
        .cta-block.col-sm-15 {
            padding-right: 15px;
        }
    }
}

@media only screen and (max-width: $screen-xs-max) {
    .cta-blocks {
        + .cta-blocks {
            margin-top: 0;
        }
        .cta-block {
            margin-top: 15px;
            > .cta-block-bg {
                &.brand {
                    height: 210px;
                }
                &.brand-auto {
                    height: auto;
                }
                &.shop {
                    height: auto;
                }
            }
        }
    }
}