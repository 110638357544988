.profileCard {
	display:flex;
	align-items:center;

	.not-profil-image {
		border-radius: 50%;
		object-fit: cover;
		background:#25cbbd;
		color:#fff;
		font-size:23px;
		text-align:center;
		padding: 35px;
	}
}

.profileCard-picture {
	border-radius: 50%;
	width: 100px;
	height: 100px;
	object-fit: cover;
}

.profileCard-titles {
	margin: 25px;
	
	.profileCard-title {

	}
	.profileCard-subtitle {
		color: $gray-dark;
	}
}

.profileSettings-btn__facebook{
	height: 100px;
}
.share-remove-btns{
	margin-top:80px;
}
.small-menu-but-content{
	position: relative;
	display: none;
	padding: 10px 10px;
	margin-bottom: 50px;
}
.alerted img.item-shop-image{
	visibility: hidden;
}
.mob-count{
	display: none;
	margin-top: 20px;
}
.close-small-menu{
	display: none;
	width: 20px!important;
	height: 20px!important;
	position: absolute;
	top: 6px;
	right:-13px;
	z-index: 9999999;
	background-size: 1em;
}

.my-shop-btn{
	position: absolute;
	z-index: 150;
	top:0;
	right: 0
}
.bread{
	position: absolute;
	left: 25px;
	z-index: 500;
	text-align: center;
}
.brand-img{
	//position: absolute;
	height:40px;
	object-fit: contain;
}

@media (max-width: 992px) {
	.profileCard {
    	justify-content: center;
    }
}
@media (max-width: 830px) {
	.small-menu-but-content{
		display: block;
	}
	.my-shop-btn, .bread{
		position: static;
	}
	.profile-list-heading-path{
		text-align: center;
	}
	.mob-count{
		display: block;
	}
	.dec-count{
		display: none;
	}
	.close-small-menu{
		display: block!important;
	}
	#profile-fiter h3{
		padding: 15px 20px;
	}
	.h-border-top{
		padding-left:20px ;
	}


	.filter-search-tags{
		text-align: center;
		width:100%;

		& li{
			display: inline-block;
			float: none;
		}
	}

	.btn-change-account-settings, .btn-remove-account{
		text-align:center;
		margin-bottom: 15px;
		width: 100%;
	}

}
