/* You forgot the 'alt' tag */
// img[alt=""],
// img:not([alt]) {
// 	border: 2px dashed #c00;
// }

/* margin bottom */
.h-mb-10 {
	margin-bottom: 10px;
}

.h-mb-15 {
	margin-bottom: 15px;
}

.h-mb-20 {
	margin-bottom: 20px;
}

.h-mb-25 {
	margin-bottom: 25px;
}

.h-mb-30 {
	margin-bottom: 30px;
}

.h-mb-40 {
	margin-bottom: 50px;
}

.h-mb-50 {
	margin-bottom: 50px;
}

.h-mb-30-negative {
	margin-bottom: -30px;
}

/* margin top */

.h-mt-2 {
	margin-top: 2px;
}

.h-mt-5 {
	margin-top: 5px;
}

.h-mt-10 {
	margin-top: 10px;
}

.h-mt-15 {
	margin-top: 20px;
}

.h-mt-20 {
	margin-top: 20px;
}

.h-mt-25 {
	margin-top: 25px;
}

.h-mt-30 {
	margin-top: 30px;
}

.h-mt-41 {
	margin-top: 41px;
}

.h-mt-45 {
	margin-top: 45px;
}

.h-mt-50 {
	margin-top: 50px;
}

// margin right

.h-mr-15 {
	margin-right: 15px;
}

// margin left

.h-ml-15 {
	margin-left: 15px;
}

// padding top

.h-pt-30 {
	padding-top: 30px;
}

.h-pt-35 {
	padding-top: 35px;
}

// padding bottom 
.h-pb-0 {
	padding-bottom: 0;
}

.h-pb-50 {
	padding-bottom: 50px;
}

// padding left
.h-pl-0 {
	padding-left: 0;
}

.h-pl-30 {
	padding-left: 30px;
}

// flex
.h-flex {
	display:flex;
	&:after {
		content: "";
		flex: auto;
	}
}
.h-flex-mid {
	align-items:center;
}

// -2% for margins.
.h-flex--full {
	flex-basis: 98%;
}
.h-flex--half {
	flex-basis: 48%;
}
.h-flex--fifth {
	flex-basis: 18%;
}

// Sizes
.h-full-width {
	width: 100%;
}
.h-half-width {
	width: 50%;
}

// Lines & borders
.h-divider-line {
	width: 100%;
	margin: 30px 0 45px;
	height: 1px;
	background-color: rgba(#fff, .1);
}

.h-border-top {
	border: 0; //resets border
	border-top: 1px;
	border-style: solid;
	border-color: $gray-lighter;
}

.h-no-border {
	border: 0;
}

.h-no-margin {
	margin: 0;
}

// Positions
.h-position-relative {
	position: relative !important;
}

.h-display-block {
	display: block;
}

.h-vertical-center { //apply on child with a parent with set height
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

// Opacity
.h-opacity-30 {
	opacity: .3;
}

.h-opacity-50 {
	opacity: .5;
}

// Other
.h-truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.wrapper-row > h1 {
	font-size: 22px;
	margin-bottom: 25px;
}

.only-mobile {
	display: none;
}
@media all and (max-width: 830px - 1){
    .only-mobile {
        display: block;
	}
    .hide-mobile {
		display: none !important;
	}
}

@media screen and (max-width: $screen-md) {
	.h-flex--fifth {
		flex-basis: 23%;
	}
}

@media screen and (max-width: $screen-sm) {

	.h-pt-35--sm-remove {
		padding-top: 0;
	}

	.h-pt-30 {
		padding-top: 0;
	}

	.h-mt-41--remove-xs {
		margin-top: 15px;
	}

	// blockifier

	.h-blockify-sm {
		display: block;

		&--center-content {
			text-align: center;

			a {
				margin-top: 10px;
			}
		}
	}
	
	.block-xs {
		display: block !important;
	}

	.h-flex--fifth {
		flex-basis: 31%;
	}
}