.o-list {
	text-decoration: none;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.o-list__inline {

	li {
		display: inline-block;
	}
}

.o-list-divider li {
	border-right: 1px solid rgba($blue-base, .4);
	padding-right: 7px;
	margin-right: 7px;

	&:last-child {
		margin-right: 0;
		padding-right: 0;
		border-right: none;
	}
}

.brandlist-container {
	border-top: 1px solid $gray-light;
}
.brandlist-letter {
	font-size: 50px;
	font-weight: lighter;
	background-color: $body-color;
	padding: 5px 0;
	z-index: 100;
}
.brandlist-items {
	.brandlist-item {
		> div {
			border-bottom:1px solid $gray-light;
			padding: 12px 0;
		}
	}
}

.o-list__alphabet {
	white-space: nowrap;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	margin-left: 15px;
	margin-right: 15px;
	font-weight: 500;
}

.alphabet-letter {
	color: $color-marine !important;
	font-size: 12px;
}

.m-flag-list {
	float: left;
}

.m-flag-list li {
	margin-right: 8px;
	cursor: pointer;

	&.last-item {
		margin-left: 15px;
		color: $gray-lighter;
	}

	img {
		max-width: 15px;
	}
}

.slide-menu-list li {
	margin-bottom: 5px;
}

.shop a > img {
	height: 75px;
	width: auto;
}


@media only screen and ( max-width: $screen-sm ) {

	.o-list__alphabet li a{
		display: table-cell;
		vertical-align: middle;
		padding-right: 20px;
		padding-left: 20px;
		height: 35px;

	}

	.brandlist-items {
		.brandlist-item {
			&:first-child {
				> div {
					border-bottom: 0;
				}
			}
		}
	}

	.m-flag-list {
		float: none;
		margin-bottom: 15px;

		img {
			margin-bottom: 10px;
		}
	}

}