body {
	
}

.text-center {
	text-align: center;
}

.text-cap {
	text-transform: uppercase;
}

.text-ls-1 {
	letter-spacing: 1px;
}

.text-ls-2 {
	letter-spacing: 2px;
}

.text-ls-025 {
	letter-spacing: .25px;
}

.text-ls-050 {
	letter-spacing: .5px;
}

.text-underline {
	text-decoration: underline;
}

.text-bold {
	font-weight: 500;//600
}

.text-medium {
	font-weight: 500;
}

.text-normal {
	font-weight: 400;
}

.text-gray {
	color: $gray;
}

.text-gray-light {
	color: $gray-light;
}

.text-gray-lighter {
	color: $gray-lighter;
}

.text-link-blue {
	color: $link-color;
}

.text-marine {
	color: $brand-marine;
}

.text-orange {
	color: $brand-orange;
}

.text-gray-blue-light {
	color: $gray-blue-light;
}

.text-gray-blue {
	color: $gray-blue;
}

.text-white {
	color: #ffffff;
}

.text-cursive {
	font-style: italic;
}

.text-tiny-grey {
	color: $gray;
	letter-spacing: 1px;
}

.text-justify {
  text-align: justify;
  text-align-last: center;
}

.text-border-bottom {
	border-bottom: 1px solid currentColor;
	padding-bottom: 2px;
}

.text-fs-14 {
	font-size: 14px !important;
}

p {
	font-size: 16px;
}

a {
	color: $base-dark;
	transition: ease .3s;
	font-size: 16px;

	&:hover {
		color: lighten($base-dark, 5%);
	}
}

article {
	line-height: 3.2rem;
	font-size: 17px;
	color: $base-dark;
	font-weight: 400;

	a {
		@extend .text-border-bottom;
		border-bottom: 1px solid $brand-orange;
		color: $brand-orange;
		font-weight: 500;

		&:hover {
			color: #dc7b41 !important;
			border-bottom: 1px solid #dc7b41;
		}
	}
}

.text-font-size-12 {
	font-size: 12px;
}

.text-font-size-13 {
	font-size: 13px;
	line-height: 2.4rem;
}

.text-font-size-16 {
	font-size: 16px;
}


@media screen and (max-width: $screen-xs) {

	.heading-paddings-xs {
		padding-left: 15px;
		padding-right: 15px;
	}

}

@media screen and (max-width: $screen-sm) {

	article {
		line-height: 2.7rem;
	}

}




