
$linkColor: #000;
$activeColor: #FE8747;

/* Pre-mob */

.mobshow{
	display: none !important; 
}
	
/* Pre-mob END */

@media(max-width: 767px){
	
	.navbar-offcanvas{
		text-align: left !important;
	}
	
	.clear{
		clear: both !important;
	}
	
	.mobhide{
		display: none;
	}
	
	.mobshow{
		display: block !important;
	}
	
	.hidden-nav{
		display: none !important;
	}	
	
	.navbar-default .navbar-offcanvas{
		background-color: white;
	
		.navbar-top{
			color: #c0c4c6;
			text-transform: uppercase;
			font-weight: 200;
			letter-spacing: 1.5px;
			text-align: left;
			height: 50px;
			line-height: 50px;
			margin-right: -15px;
			margin-left: -15px;
			padding-right: 15px;
			padding-left: 15px;
			font-size: 16px;
			
			object{
				height: 20px;
				width: 20px;
				position: absolute;
				top: 14px;
				right: 15px;
			}
		}
		
		
		.back-svg{
			position: static;
		    display: inline-block !important;
		    -webkit-transform: rotate(90deg);
		    -moz-transform: rotate(90deg);
		    -o-transform: rotate(90deg);
		    -ms-transform: rotate(90deg);
		    transform: rotate(180deg);
		    vertical-align: middle;
		    margin-top: -4px;
		    margin-left: -10px;
		}
	} // .navbar-default .navbar-offcanvas
	
	
	

	.mega-dropdown{
		clear: both;
		
		.dropdown-menu{
			position: static;
		}
	}

	 ul.navbar-nav:not(#searchresults){
		 margin-top: 0px !important;
		li{
			.dropdown-header{
				display: none;
			}
			a{
				color: $linkColor;
				border-bottom: 1px solid #EFF0F2;
				height: 50px;
				line-height: 32px;
				font-size: 16px;
						  
					&.dropdown-toggle{
						font-weight: 500;
					}
						  
					object{
						height: 20px;
						width: 20px;
						text-align: right;
						position: absolute;
						right: 15px;
						top: 14px;
					}			  
				}
			} // .li (general)
				 
			li.gender-box{
					 text-align: left;
					 border-bottom: 1px solid #EFF0F2;
					 display: block;
					 a{
						display: inline-block !important;
						width: 65px;
						color: $linkColor;
						margin: 0px; 
						text-align: center;
						text-transform: uppercase;
						font-size: 14px;
						letter-spacing: 0.5px;
						font-weight: 500;
						 
						 &.active{
							 color: $activeColor;
							 border-bottom: 1px solid $activeColor;
						 }
					 }
				 } // li.gender-box
				 
				 
				 
			 } // .navbar-nav

	
	
	.navlink {
	  position: static !important;
	}
	
	.navlink-menu {
	    position: absolute;
	    top: 35px;
	    padding: 10px 0px 0px;
	    width: 100%;
	    box-shadow: none;
	    border: none;
	    z-index: 2010;
	}
	
	.navlink > a {
	    text-transform: uppercase;
	    color: $brand-marine !important;
	    font-weight: 500;
	    letter-spacing: .75px;
	}
	
	.navlink-menu > li > ul {
	  padding: 0;
	  margin: 0;
	}
	
	.navlink-menu > li > ul > li {
	  list-style: none;
	}
	
	.navlink-menu > li > ul > li > a {
	  display: block;
	  color: $brand-marine;
	  padding: 7px 5px 7px 0;
	  font-size: 15px;
	  letter-spacing: 1px;
	
	    &:hover {
	        color: $base-dark;
	    }
	}
	
	.navlink-inner-btn {
	    margin-top: 7px;
	}
	
	.navlink-menu > li ul > li > a:hover,
	.navlink-menu > li ul > li > a:focus {
	  text-decoration: none;
	  background-color: none;
	} 
	
	.navlink-menu .dropdown-header {
	  font-size: 14px;
	  letter-spacing: 1.5px;
	  color: $gray-light;
	  padding: 0px;
	  line-height: 30px;
	  text-transform: uppercase;
	  font-weight: 400;
	}
	
	.city-btn{
		margin-top: 30px;
		margin-left: 20px;
		margin-right: 20px;
		
		a{
			text-align: center !important;
			border-bottom: none !important;
		}
		
		.city-btn-inner{
			border: 1px solid #25cbbd;
			height: 42px;
			color: #25cbbd;
			text-transform: uppercase;
			letter-spacing: 1.5px;
			font-size: 14px;
			border-radius: 21px;
			line-height: 42px;
			font-weight: 400;
			
			object{
				height: 17px;
				width: 17px;
				display: inline-block !important;
				vertical-align: middle;
				margin-left: 5px;
				margin-top: -2px;
				position: static !important;
				
			}
		}
	}
	
	#backdrop{
		top: 0px !important;
	}
	
	
} // max-width: 767

