.row .m-brand-box {
	position: relative;
	float: left;
	width: 18%;
	margin-right: 30px;
	border: 1px solid $gray-light;
	text-align: center;
	border-radius: 3px;
	margin-top: 15px;

	&:last-child {
		margin-right: 0;
	}
}

.m-brand-box:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.m-brand-box__content {
	position: absolute;
	width: 100%;
	height: 100%;
}

.m-brand-box__content-image {
	position: relative;
	top: 0;
	height: 45%;
	width: 100%;
	text-align: center;

	img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		max-width: 95px;
	}
}
.brand-fl{
	width: 30px;
	height: 30px;
	margin-top: -4px;
	border-radius: 100%;
	background-size: 12px;
	background-position: center center;
	border:1px solid #ccc;
	background-repeat: no-repeat;
	float: right
}
.brand-fl.plus-fl{
	background-image: url('/gfx/icons/add-blue.svg');
	&:hover {
		background-image: url('/gfx/icons/add-white.svg');
	}
}
.brand-fl.active{
	background-image: url('/gfx/icons/remove.svg');
	background-color: #041d26;
	border-color: #041d26;
	&:hover {
		background-color: $body-color;
		background-image: url('/gfx/icons/remove-blue.svg');
	}
}
.brand-fl:hover{
	background-color:#041d26;
	border-color: #041d26;
	cursor: pointer;
}

.brands-pop-2{
	padding: 5% 15% 0;
	overflow: hidden;
	height: 270px;
	background-repeat:no-repeat ;
	background-size:96%;
}

.m-brand__name {
	font-weight: 400;
}

.m-brand__shoes {
	display: block;
	font-weight: 500;
}

@media (width: 1230px) {
	
	.hidden-md-fix {
		display: none !important;
	}

}

@media (min-width: $screen-md) and (max-width: $screen-lg) {

	.row .m-brand-box {
		width: 49%;
		margin-right: 15px;

	}

}

@media screen and (max-width: $screen-md-max) {

	.row .m-brand-box {
		width: 22.6%;
		margin-right: 30px;

		&:nth-child(4) {
			margin-right: 0;
		}
	}

}

@media screen and (max-width: $screen-sm-max) {

	.row .m-brand-box {
		width: 48%;
		margin-right: 15px;

		&:nth-child(even) {
			margin-right: 0;
		}
	}

}

@media screen and (max-width: $screen-xs-max) {

	.row .m-brand-box {
		width: 49%;
		margin-right: 0;
		margin-top: 2%;

		&:nth-child(odd) {
			float: right;
		}
	}

	.m-brand-box__content-image img {
		max-width: 40%;
	}

}