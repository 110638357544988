.modal-backdrop {
  background-color: $brand-marine;

  &.in {
    opacity: .2;
  }
}

.modal-wrapper-small{
  width: 660px;
  padding: 0 30px;
}
.fm-modal {
  .form-control {
    font-size: inherit;
  }
  .m-radio-btn-container .radio {
    margin-top: 0;
  }
}

.modal-header {
  border-bottom: 0;
  margin-bottom: 20px;
}
.fm-modal-watch-item .modal-header {
  margin-bottom: 0;
}

.modal-item-hero {
  .item {
    margin: 0 auto 30px;
    float: none;
  }
}

.modal-wrapper-item{
  h2 {
    margin: 0 30px .5em 0;
    font-size: $font-large;
  }
  
  h3{
    margin: 15px 0 0 0;
    font-size: 1em;
    font-weight: normal;

    a {
      color: $gray;
    }
  }
  .influencer-modal-attribution h2 {
    margin: 1em 0 0 0;
  }
}
.modal-container {
  position: relative;
  padding: 60px 30px;
  max-width: 600px;
  width: 100% !important;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.close {
  opacity: 1;
}

.modal-padding {
  padding-left: 55px;
  padding-right: 55px;
}

.modal-shoe-image {
  max-width: 210px;
}

.modal-country {
  position: relative;


  img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
  }

  p {
    line-height: 42px;
    float: left;
  }
}

.item-full {
  float: left;
  //border-top: 1px solid $gray-border;
  padding: 24px;
  width: 100%;

  .influencer-item,
  .item {
    margin: 0 0 12px 12px;
    &:first-of-type {
      margin-left: 0;
    }
  }
}

.item-half {
  width: 40%;
  float: left;
  padding: 0;
  //border-left: 1px solid $gray-border;

  &:first-of-type {
    border-left: 0;
    width: 60%;
  }
}


.item-image-wrapper {
  text-align: center;
  margin-top: 24px;
  min-height: 300px;
  position: relative;

  a {
    display: inline-block;
  }

  img {
    vertical-align: middle;
    max-width: 100%;
    width: inherit;
    max-height: 530px;
  }
}

.item-price {
  font-size: 1.5em;
  margin-bottom: 24px;
}

.item-meta-list {
  display: block;
  margin: 0 0 24px 0;
}

.item-shop-meta {
  //background-color: $redesign-turquoise;
  padding: 24px;

  img {
    float: left;
    width: inherit;
  }
  .rating-wrapper {
    margin: 0;
    display: inline-block;
    margin: 12px 0 0 24px;
  }
}

.modal-footer {
  text-align: center;
  border-top: 0;
  p {
    font-size: 12px;
  }
}

@media all and (max-width: 830px) {
  .modal-wrapper-small{
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }
}
