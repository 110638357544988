.instagram-section {  

    .instagram-item {        
        width: 20%;
        display: inline-block;
        vertical-align: top;
        
        .instagram-item-inner {
            margin: 15px;
            overflow: hidden;
            border-radius: 3px;
            position: relative;

            .instagram-image {
                max-width: 100%;
                border-radius: 3px;
                position: absolute;
                margin-right: auto;
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }
}


@media screen and (max-width: $screen-md) {

    .instagram-section {  

        .instagram-item {        
            width: 50%;
            display: block;
            float: left;
        }

        .instagram-picture-4 {
            display: none;
        }
    }
}

@media screen and (max-width: $screen-xs) {

    .instagram-section {  

        .instagram-item {        
            width: 100%;
            display: block;
            float: left;
        }
    }
}